import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { Link } from 'react-router-dom'
import Preloader from '../Preloder/Preloader'
const Certificate = () => {
  return (
    <div>
      <Header/>
       <Preloader/>
      <section className="page-header @@extraClassName">
        <div className="page-header__bg" style={{backgroundImage:' url("assets/images/backgrounds/page-header-bg-1-1.jpg")'}}></div>
        {/* /.page-header__bg */}
        <div className="container">
            <h2 className="page-header__title">Certificate</h2>
            <ul className="cleenhearts-breadcrumb list-unstyled">
                <li><i className="icon-home"></i> <Link to="/">Home</Link> </li>
                <li><span>Certificate</span></li>
            </ul>{/* /.thm-breadcrumb list-unstyled */}
        </div>{/* /.container */}
    </section>{/* /.page-header */}
      <Footer/>
    </div>
  )
}

export default Certificate
