import React from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
    return (
        <div>
            <section className="subscribe">
                <div className="subscribe__bg" style={{ backgroundImage: 'url("assets/images/backgrounds/subscribe-bg-1-1.jpg")' }}>
                </div>{/* /.subscribe__bg */}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 wow fadeInUp" data-aos="fade-up" data-aos-duration="1000">
                            <div className="subscribe__content">
                                <span className="subscribe__title-image icon-email"></span>
                                <h2 className="subscribe__title">Subscribe Now</h2>
                            </div>{/* /.subscribe__content */}
                        </div>{/* /.col-lg-5 */}
                        <div className="col-lg-7 wow fadeInUp">
                            <form action="#" data-url="MAILCHIMP_FORM_URL" className="subscribe__form mc-form"
                                data-wow-delay="200ms" data-wow-duration="1500ms">
                                <input type="email" name="EMAIL" id="subscribe" placeholder="enter your email"
                                    className="subscribe__form__input" />
                                <button type="submit" className="subscribe__form__btn">
                                    <span className="subscribe__form__btn__text">Subscribe now</span>
                                    <span className="subscribe__form__btn__icon icon-paper-plane"></span>
                                </button>
                            </form>{/* /.subscribe__form */}
                            <div className="mc-form__response"></div>{/* /.mc-form__response */}
                        </div>{/* /.col-lg-7 */}
                    </div>{/* /.row */}
                </div>{/* /.container */}
                <div className="subscribe__shape">
                    <div className="subscribe__shape__one"></div>{/* /.subscribe__shape__one */}
                    <div className="subscribe__shape__two"></div>{/* /.subscribe__shape__one */}
                </div>{/* /.subscribe__shape */}
            </section>{/* /.subscribe */}

            <footer className="main-footer background-white2">
                <div className="main-footer__top">
                    <div className="container">
                        <div className="row gutter-y-30">
                            <div className="col-md-12 col-xl-3 wow fadeInUp" data-aos="fade-up" data-aos-duration="1000">
                                <div className="footer-widget footer-widget--about">
                                    <Link to='/' className="footer-widget__logo">
                                        <img src="assets/logo.png" width="159" alt="Cleenhearts HTML Template" />
                                    </Link>
                                    <p className="footer-widget__about-text">Wimply dummy text of the priatype industry orem
                                        Ipsum has Maecenas quis eros at ante</p>
                                </div>{/* /.footer-widget */}
                                <a href="https://www.google.com/maps/place/Ramji+Trust/@8.210541,77.247346,913m/data=!3m1!1e3!4m6!3m5!1s0x3b04fe8c669eff13:0x663b0ba37a4543ef!8m2!3d8.2105408!4d77.2473462!16s%2Fg%2F11mw6ryftc?hl=en&entry=ttu&g_ep=EgoyMDI0MTIwNC4wIKXMDSoASAFQAw%3D%3D"
                                    className="footer-widget__map">
                                    <span className="footer-widget__map__text">View Map</span>
                                    <span className="icon-paper-plane"></span>
                                </a>
                            </div>{/* /.col-md-12 col-xl-3 */}
                            <div className="col-xl-3 col-md-5 wow fadeInUp" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                                <div className="footer-widget footer-widget--contact">
                                    <h2 className="footer-widget__title">Get in touch!</h2>{/* /.footer-widget__title */}
                                    <ul className="list-unstyled footer-widget__info">
                                        <li> <span className="icon-location"></span>
                                            <address>10/26 Koothan vilai,Palappallam Post -629159,Kanniyakumari </address>
                                        </li>
                                        <li> <span className="icon-phone"></span><a href="tel:+919843976039">Call +91 98439 76039</a>

                                        </li>
                                        <li> <span className="icon-envelope"></span><a
                                            href="mailto:info@ramjitrust.org">info@ramjitrust.org</a></li>
                                    </ul>{/* /.list-unstyled */}
                                </div>{/* /.footer-widget */}
                            </div>{/* /.col-xl-3 col-md-5 */}
                            <div className="col-md-3 wow fadeInUp" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
                                <div className="footer-widget footer-widget--links">
                                    <h2 className="footer-widget__title">Quick Links</h2>{/* /.footer-widget__title */}
                                    <ul className="list-unstyled footer-widget__links">
                                        <li><Link to='/aboutus'>About Us</Link></li>
                                        <li><Link to='/donate'>Give Donation</Link></li>
                                        <li><Link to='/exam'>Exam portal</Link></li>
                                        <li><Link to='/program'>Our Campaign</Link></li>
                                        <li><Link to='/contact'>Contact Us</Link></li>
                                    </ul>{/* /.list-unstyled footer-widget__links */}
                                </div>{/* /.footer-widget */}
                            </div>{/* /.col-md-3 */}
                            <div className="col-md-4 col-xl-3 wow fadeInUp" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">
                                <div className="footer-widget footer-widget--gallery">
                                    <h2 className="footer-widget__title">Our Gallery</h2>{/* /.footer-widget__title */}
                                    <div className="footer-widget__gallery">
                                        <div className="footer-widget__gallery__link">
                                            <img src="assets/images/gallery/footer-widget-gallery-img-1.jpg"
                                                alt="footer-widget-gallery" />
                                            <span className="footer-widget__gallery__icon icon-plus"></span>
                                        </div>{/* /.footer-widget__gallery__link */}
                                        <div className="footer-widget__gallery__link">
                                            <img src="assets/images/gallery/footer-widget-gallery-img-2.jpg"
                                                alt="footer-widget-gallery" />
                                            <span className="footer-widget__gallery__icon icon-plus"></span>
                                        </div>{/* /.footer-widget__gallery__link */}
                                        <div className="footer-widget__gallery__link">
                                            <img src="assets/images/gallery/footer-widget-gallery-img-3.jpg"
                                                alt="footer-widget-gallery" />
                                            <span className="footer-widget__gallery__icon icon-plus"></span>
                                        </div>
                                        {/* /.footer-widget__gallery__link */}
                                        <div className="footer-widget__gallery__link">
                                            <img src="assets/images/gallery/footer-widget-gallery-img-4.jpg"
                                                alt="footer-widget-gallery" />
                                            <span className="footer-widget__gallery__icon icon-plus"></span>
                                        </div>{/* /.footer-widget__gallery__link */}
                                        <div className="footer-widget__gallery__link">
                                            <img src="assets/images/gallery/footer-widget-gallery-img-5.jpg"
                                                alt="footer-widget-gallery" />
                                            <span className="footer-widget__gallery__icon icon-plus"></span>
                                        </div>{/* /.footer-widget__gallery__link */}
                                        <div className="footer-widget__gallery__link">
                                            <img src="assets/images/gallery/footer-widget-gallery-img-6.jpg"
                                                alt="footer-widget-gallery" />
                                            <span className="footer-widget__gallery__icon icon-plus"></span>
                                        </div>{/* /.footer-widget__gallery__link */}
                                    </div>{/* /.footer-widget__gallery */}
                                </div>{/* /.footer-widget */}
                            </div>{/* /.col-md-4 col-xl-3 */}
                        </div>{/* /.row */}
                    </div>{/* /.container */}
                    <div className="main-footer__top__shape-box">
                        <img src="assets/images/shapes/footer-shape-hand-1-1.png" alt="hand"
                            className="main-footer__top__shape-one" />
                        <img src="assets/images/shapes/footer-shape-hand-1-2.png" alt="hand"
                            className="main-footer__top__shape-two" />
                    </div>{/* /.main-footer__top__shape-box */}
                </div>{/* /.main-footer__top */}
                <div className="main-footer__bottom"  >
                    <div className="main-footer__bottom__bg"
                        style={{ background: ' url("assets/images/backgrounds/footer-bottom-bg-1-2.png")' }}></div>
                    {/* /.main-footer__bottom-bg */}
                    <div className="container">
                        <div className="main-footer__bottom__inner">
                            <p className="main-footer__copyright">
                                &copy; Copyright <span className="dynamic-year"></span> RamjiTrust All Rights Reserved.
                            </p>
                        </div>{/* /.main-footer__inner */}
                    </div>{/* /.container */}
                </div>{/* /.main-footer__bottom */}
            </footer>{/* /.main-footer */}


            <div className="mobile-nav__wrapper">
                <div className="mobile-nav__overlay mobile-nav__toggler"></div>
                {/* /.mobile-nav__overlay */}
                <div className="mobile-nav__content">
                    <span className="mobile-nav__close mobile-nav__toggler"><i className="fa fa-times"></i></span>

                    <div className="logo-box">
                        <Link to='/' aria-label="logo image"><img src="assets/images/logo-light.png" width="155"
                            alt="" /></Link>
                    </div>
                    {/* /.logo-box */}
                    <div className="mobile-nav__container"></div>
                    {/* /.mobile-nav__container */}

                    <ul className="mobile-nav__contact list-unstyled">
                        <li>
                            <i className="fa fa-envelope"></i>
                            <a href="mailto:info@ramjitrust.org">info@ramjitrust.org</a>
                        </li>
                        <li>
                            <i className="fa fa-phone-alt"></i>
                            <a href="tel:+919843976039">+91 98439 76039</a>
                        </li>
                    </ul>{/* /.mobile-nav__contact */}
                    <div className="mobile-nav__social">
                        <a href="https://facebook.com/">
                            <i className="fab fa-facebook-f" aria-hidden="true"></i>
                            <span className="sr-only">Facebook</span>
                        </a>
                        <a href="https://twitter.com/">
                            <i className="fab fa-twitter" aria-hidden="true"></i>
                            <span className="sr-only">Twitter</span>
                        </a>
                        <a href="https://linkedin.com/" aria-hidden="true">
                            <i className="fab fa-linkedin-in"></i>
                            <span className="sr-only">Linkedin</span>
                        </a>
                        <a href="https://youtube.com/" aria-hidden="true">
                            <i className="fab fa-youtube"></i>
                            <span className="sr-only">Youtube</span>
                        </a>
                    </div>{/* /.mobile-nav__social */}
                </div>
                {/* /.mobile-nav__content */}
            </div>
            {/* /.mobile-nav__wrapper */}
            <div className="search-popup @@extraClassName">
                <div className="search-popup__overlay search-toggler"></div>
                {/* /.search-popup__overlay */}
                <div className="search-popup__content">
                    <form role="search" method="get" className="search-popup__form" action="#">
                        <input type="text" id="search" placeholder="Search Here..." />
                        <button type="submit" aria-label="search submit" className="cleenhearts-btn">
                            <span><i className="icon-search"></i></span>
                        </button>
                    </form>
                </div>
                {/* /.search-popup__content */}
            </div>
            {/* /.search-popup */}

            <a href="#" data-target="html" className="scroll-to-target scroll-to-top">
                <span className="scroll-to-top__text">back top</span>
                <span className="scroll-to-top__wrapper"><span className="scroll-to-top__inner"></span></span>
            </a>


        </div>

    )
}

export default Footer
