import React from 'react'
import { NavLink,Link } from 'react-router-dom'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import "../About_us/About.css"
import Preloader from '../Preloder/Preloader'
import AOS from 'aos';
import 'aos/dist/aos.css'; 
AOS.init();
const About = () => {
  return (
    <div style={{width:'100%',overflow:'hidden'}}>
      
<body ClassName="custom-cursor">

<Preloader/>

<div ClassName="page-wrapper">
   

  <Header/>
  <section className="page-header @@extraClassName" >
        <div className="page-header__bg"  style={{backgroundImage:' url("assets/images/backgrounds/page-header-bg-1-1.jpg")'}}></div>
        {/* /.page-header__bg */}
        <div className="container">
            <h2 className="page-header__title">About us</h2>
            <ul className="cleenhearts-breadcrumb list-unstyled">
                <li><i className="icon-home"></i> <Link to="/">Home</Link> </li>
                <li><span>About us</span></li>
            </ul>{/* /.thm-breadcrumb list-unstyled */}
        </div>{/* /.container */}
    </section>{/* /.page-header */}

   <div className='about_body'>
   <div className='about__details_main' >
          {/* <div className='about__details_main_img'><img src="assets/images/team/team-1-3.jpg" alt="" /></div> */}
          <div className='about__details_main_contant' data-aos="fade-up" data-aos-duration="1000"><h2>தமிழ்நாடு ராம்ஜி கல்வி மற்றும் பொருளாதார மேம்பாட்டு அறக்கட்டளை அரசால் பதிவு பெற்ற தொண்டு நிறுவனமாகும். பதிவு எண் 38/2015.</h2></div>
        </div>
 <div className='about_founter '>
    <div className='about_founter_1 ' data-aos="fade-up" data-aos-duration="1000">
        
       <h1>நிறுவனர்</h1>
        <h3>திரு.K.ஹாக்கின்ஸ் B.E</h3>
    </div >
    <div className='about_founter_2' data-aos="fade-up" data-aos-duration="1000">
        
        <h1>அறங்காவலர்</h1>
        <h3>திருமதி.G.சரிதா B.Sc</h3>
    </div>
</div>

<div className='about_vison' >
  <h2 data-aos="fade-up" data-aos-duration="1500">நோக்கம் கல்வி மற்றும் பொருளாதார முன்னேற்றத்திற்கான வாய்ப்புகளை உருவாக்குதல் மற்றும் தொழிற்பயிற்சி வழங்குதல்</h2>
</div>

   </div>

</div>{/* /.page-wrapper */}





<Footer/>

</body>

    </div>
  )
}

export default About
