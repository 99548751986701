import React, { useEffect, useState } from 'react';

const Preloader = () => {
  const [loading, setLoading] = useState(true);

  // Hide the preloader after a certain period (e.g., 3 seconds)
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the delay as needed (in milliseconds)
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="preloader" >
        <div className="preloader__image" style={{ backgroundImage: 'url("assets/logo.png")' }}></div>
      </div>
    );
  } else {
    return null;
  }
};

export default Preloader;
