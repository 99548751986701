import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Preloader from '../Preloder/Preloader';

const Login = () => {
  // State to manage the password visibility
  const [showPassword, setShowPassword] = useState(false);
  

  // Toggle the password visibility
  const handleShowPasswordChange = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <Preloader />

      <div className="page-wrapper">
        <Header />

        <section className="page-header">
          <div className="page-header__bg" style={{ backgroundImage: 'url("assets/images/backgrounds/page-header-bg-1-1.jpg")' }}></div>
          <div className="container">
            <h2 className="page-header__title">Login</h2>
            <ul className="cleenhearts-breadcrumb list-unstyled">
              <li><i className="icon-home"></i> <Link to="/">Home</Link></li>
              <li><span>Login</span></li>
            </ul>
          </div>
        </section>
        
        <section className="login-page section-space">
          <div className="container">
            <div className="row gutter-y-40">
              <div className="col-lg-6 wow fadeInUp" data-aos="fade-up-right"  data-aos-duration="1000">
                <div className="login-page__image">
                  <img src="assets/images/login/login-1-1.jpg" alt="login" />
                </div>
              </div>
             
              <div className="col-lg-6 wow fadeInUp" data-aos="fade-up-left"  data-aos-duration="1000">
                <div className="login-page__form">
                  <span className="login-page__form__top-title" style={{backgroundColor:'#ffd767'}}>Log in</span>
                  <span> </span>
                  <Link to='/signin'>
                    <span className="login-page__form__top-title">Signin</span>
                  </Link>
                  <h2 className="login-page__form__title">Welcome to our community</h2>

                  <form className="login-page__form__box form-one">
                    <div className="login-page__form__input-box form-one__control">
                      <input type="email" placeholder="Email address" className="form-one__control__input" required />
                    </div>
                    <div className="login-page__form__input-box form-one__control">
                      <input 
                        type={showPassword ? "text" : "password"} 
                        placeholder="Password" 
                        className="form-one__control__input" 
                        required 
                      />
                    </div>

                    <div className="login-page__form__input-box login-page__form__checkbox-forgot">
                      <div className="login-page__form__checkbox">
                        <input 
                          type="checkbox" 
                          id="save_login" 
                          checked={showPassword} 
                          onChange={handleShowPasswordChange} 
                        />
                        <label htmlFor="save_login">Show Password</label>
                      </div>
                      <Link to="/forgot-password" className="login-page__form__forgot">Forgot your password?</Link>
                    </div>

                    <div className="login-page__form__input-box">
                      <button type="submit" className="cleenhearts-btn">
                        <span className="cleenhearts-btn__icon-box">
                          <span className="cleenhearts-btn__icon-box__inner"><span className="icon-duble-arrow"></span></span>
                        </span>
                        <span className="cleenhearts-btn__text">Log in</span>
                      </button>
                    </div>

                    {/* <div className="login-page__form__or">
                      <hr className="login-page__form__or-line" />
                      <span className="login-page__form__or-text">Or</span>
                      <hr className="login-page__form__or-line" />
                    </div> */}

                    {/* <div className="login-page__form__signin">
                      <button type="button" className="login-page__form__signin-btn">
                        <span>Sign in with Facebook</span>
                        <img src="assets/images/resources/login-facebook.png" alt="Facebook" />
                      </button>
                      <button type="button" className="login-page__form__signin-btn">
                        <span>Sign in with Google</span>
                        <img src="assets/images/resources/login-google.png" alt="Google" />
                      </button>
                      <p className="login-page__form__register-text">Don’t have an account? <Link to="/signin">Register</Link></p>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>

      <Footer />
    </div>
  );
}

export default Login;
