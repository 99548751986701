import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import '../Header/Header.css'
const Header = () => {
  const [slide, setSlide] = useState(false);
  const [nav, setNav] = useState(false);
  const cursorRef = useRef(null);
  const cursorInnerRef = useRef(null);
  const [isHoveringLink, setIsHoveringLink] = useState(false);
  const [isClicking, setIsClicking] = useState(false);

  const handleclick = () => {
    setSlide(!slide);
  };

  const handleMobileNavToggle = () => {
    setNav(!nav);
  };

  useEffect(() => {
    // Only execute if refs are properly set
    const cursor = cursorRef.current;
    const cursorInner = cursorInnerRef.current;

    // Check if cursor and cursorInner exist
    if (cursor && cursorInner) {
      const handleMouseMove = (e) => {
        const { clientX, clientY } = e;
        cursor.style.transform = `translate3d(calc(${clientX}px - 50%), calc(${clientY}px - 50%), 0)`;
        cursorInner.style.left = `${clientX}px`;
        cursorInner.style.top = `${clientY}px`;
      };

      const handleMouseDown = () => {
        setIsClicking(true);
      };

      const handleMouseUp = () => {
        setIsClicking(false);
      };

      const handleLinkMouseOver = () => {
        setIsHoveringLink(true);
      };

      const handleLinkMouseLeave = () => {
        setIsHoveringLink(false);
      };

      // Add event listeners
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mousedown', handleMouseDown);
      document.addEventListener('mouseup', handleMouseUp);

      const links = document.querySelectorAll('a');
      links.forEach((link) => {
        link.addEventListener('mouseover', handleLinkMouseOver);
        link.addEventListener('mouseleave', handleLinkMouseLeave);
      });

      // Cleanup event listeners on component unmount
      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mousedown', handleMouseDown);
        document.removeEventListener('mouseup', handleMouseUp);
        links.forEach((link) => {
          link.removeEventListener('mouseover', handleLinkMouseOver);
          link.removeEventListener('mouseleave', handleLinkMouseLeave);
        });
      };
    }
  }, []);

  return (
    <div>
      {/* Custom Cursor */}
      <div
        className={`custom-cursor__cursor ${isClicking ? 'click' : ''} ${isHoveringLink ? 'custom-cursor__hover' : ''}`}
        ref={cursorRef}
      ></div>
      <div
        className={`custom-cursor__cursor-two ${isClicking ? 'custom-cursor__innerhover' : ''}`}
        ref={cursorInnerRef}
      ></div>

      <header className="main-header sticky-header sticky-header--normal">
        <div className='header_logo_main'>
          <Link to='/'  className="logo_image">
            <img
              src="assets/headimg.jpg"
              alt="Cleenhearts HTML"
             
              style={{ width: '100%' }}
            />
          </Link>
          <div
                className={`mobile-nav__btn mobile-nav__toggler ${nav ? 'icon-close' : 'icon-grid'}`}
                onClick={handleMobileNavToggle}
              >
                
              </div>
        </div>
        <div className="container-fluid">
          <div className="main-header__inner">
            <div className="main-header__logo">
              <button
                type="button"
                onClick={handleclick}
                className="main-header__sidebar-btn sidebar-btn__toggler"
              >
                <span className="icon-grid"></span>
              </button>
            </div>
            <div className="main-header__right">
              <nav className="main-header__nav main-menu">
                <ul className="main-menu__list">
                  <li>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li>
                    <NavLink to="/aboutus">About Us</NavLink>
                  </li>
                  <li className="dropdown">
                    <NavLink to='/program'>Program</NavLink>
                  </li>
                  <li className="dropdown">
                    <NavLink to='/certification'>Certificate</NavLink>
                  </li>
                  <li className="dropdown">
                    <NavLink to='/exam'>Exam portal</NavLink>
                  </li>
                  <li className="dropdown">
                    <NavLink to="/login">Login</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact">Contact Us</NavLink>
                  </li>
                </ul>
              </nav>

             

              <div className="main-header__cart">
                {/* Cart component */}
              </div>

              <NavLink  to="/donate" className="cleenhearts-btn main-header__btn">
                <div className="cleenhearts-btn__icon-box">
                  <div className="cleenhearts-btn__icon-box__inner">
                    <span className="icon-donate"></span>
                  </div>
                </div>
               
                  <span className="cleenhearts-btn__text">Donate now</span>
                
              </NavLink>
            </div>
          </div>
        </div>
      </header>

      {/* Sidebar */}
      <aside
        className="sidebar-one"
        style={{
          transform: slide ? 'translateX(0)' : 'translateX(100%)',
          visibility: slide ? 'visible' : 'hidden',
          transition:
            slide
              ? 'transform 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms'
              : 'transform 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms',
        }}
      >
        <div className="sidebar-one__overlay sidebar-btn__toggler" onClick={handleclick}></div>
        <div
          className="sidebar-one__content"
          style={{
            transform: slide ? 'translateX(0)' : 'translateX(100%)',
            visibility: slide ? 'visible' : 'hidden',
            opacity: slide ? '1' : '0',
            transition:
              slide
                ? 'opacity 500ms ease 500ms, visibility 500ms ease 500ms, transform 500ms ease 500ms, -webkit-transform 500ms ease 500ms'
                : 'opacity 500ms ease 0ms, visibility 500ms ease 0ms, transform 500ms ease 0ms, -webkit-transform 500ms ease 0ms',
          }}
        >
          <span className="sidebar-one__close sidebar-btn__toggler" onClick={handleclick}>
            <i className="fa fa-times"></i>
          </span>
          <div className="sidebar-one__logo sidebar-one__item">
            <a href="index.html" aria-label="logo image">
              <img src="assets/logo.png" width="155" alt="logo-dark" />
            </a>
          </div>
          <div className="sidebar-one__about sidebar-one__item">
            <p className="sidebar-one__about__text">
              Wimply dummy text of the priatype industry orem Ipsum has Maecenas quis eros at ante
            </p>
          </div>
          <div className="sidebar-one__information sidebar-one__item">
            <h4 className="sidebar-one__title">Information</h4>
            <ul className="sidebar-one__information__list">
              <li>
                <span className="sidebar-one__information__icon icon-location"></span>
                <address className="sidebar-one__information__address">
                  <p className="sidebar-one__information__text">
                    10/26 Koothan vilai,Palappallam Post -629159,Kanniyakumari District
                  </p>
                </address>
              </li>
              <li>
                <i className="sidebar-one__information__icon icon-envelope"></i>
                <p className="sidebar-one__information__text">
                  <a href="mailto:info@ramjitrust.org">info@ramjitrust.org</a>
                </p>
              </li>
              <li>
                <span className="sidebar-one__information__icon icon-phone"></span>
                <p className="sidebar-one__information__text">
                  <a href="tel:+91 98439 76039">+91 98439 76039</a>
                </p>
              </li>
            </ul>
          </div>
          <div className="sidebar-one__socila social-link sidebar-one__item">
            <a href="https://facebook.com/">
              <i className="fab fa-facebook-f" aria-hidden="true"></i>
              <span className="sr-only">Facebook</span>
            </a>
            <a href="https://twitter.com/">
              <i className="fab fa-twitter" aria-hidden="true"></i>
              <span className="sr-only">Twitter</span>
            </a>
            <a href="https://linkedin.com/" aria-hidden="true">
              <i className="fab fa-linkedin-in"></i>
              <span className="sr-only">Linkedin</span>
            </a>
            <a href="https://youtube.com/" aria-hidden="true">
              <i className="fab fa-youtube"></i>
              <span className="sr-only">Youtube</span>
            </a>
          </div>
          <div className="sidebar-one__newsletter sidebar-one__item">
            <label className="sidebar-one__title" htmlFor="sidebar-email">
              Newsletter Subscribe
            </label>
            <form action="#" className="sidebar-one__newsletter__inner mc-form" data-url="MAILCHIMP_FORM_URL">
              <input
                type="email"
                name="sidebar-email"
                id="sidebar-email"
                className="sidebar-one__newsletter__input"
                placeholder="Email Address"
              />
              <button type="submit" className="sidebar-one__newsletter__btn">
                <i className="fa fa-paper-plane" aria-hidden="true"></i>
              </button>
            </form>
            <div className="mc-form__response"></div>
          </div>
        </div>
      </aside>

      {/* Mobile Navigation */}
      <div className="mobile-nav__wrapper"  style={{
        visibility :nav ?'visible' : 'hidden',
        transform:nav ? '  translateX(0%)':' translateX(-100%)',
        transition:
        nav
          ? 'transform 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms'
          : 'transform 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms',
    }}>
        <div className="mobile-nav__overlay mobile-nav__toggler"></div>
        <div className="mobile-nav__content" style={{
          opacity:nav?'1':'0',
           visibility :nav ?'visible' : 'hidden',
           transition:
           slide
             ? 'opacity 500ms ease 500ms, visibility 500ms ease 500ms, transform 500ms ease 500ms, -webkit-transform 500ms ease 500ms'
             : 'opacity 500ms ease 0ms, visibility 500ms ease 0ms, transform 500ms ease 0ms, -webkit-transform 500ms ease 0ms'
        }}>
          <span className="mobile-nav__close mobile-nav__toggler" onClick={()=>setNav(!nav)}>
            <i className="fa fa-times"></i>
          </span>
          <div className="logo-box">
            <a href="index.html" aria-label="logo image">
              <img src="assets/logo.png" width="155" alt="" />
            </a>
          </div>
          <nav className="">
                <ul className="side-menu__list">
                  <li>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li>
                    <NavLink to="/aboutus">About Us</NavLink>
                  </li>
                  <li className="dropdown">
                  <NavLink to='/program'>Program</NavLink>
                  </li>
                  <li className="dropdown">
                  <NavLink to='/certification'>Certificate</NavLink>
                  </li>
                  <li className="dropdown">
                  <NavLink to='/exam'>Exam portal</NavLink>
                  </li>
                  <li className="dropdown">
                    <NavLink to="/login">Login</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact">Contact Us</NavLink>
                  </li>
                  <li>
                    <NavLink to='/donate'>Donate now</NavLink>
                  </li>
                </ul>
              </nav>
          <ul className="mobile-nav__contact list-unstyled">
            <li>
              <i className="fa fa-envelope"></i>
              <a href="mailto:info@ramjitrust.org">info@ramjitrust.org</a>
            </li>
            <li>
              <i className="fa fa-phone-alt"></i>
              <a href="tel:+919843976039">+91 98439 76039</a>
            </li>
          </ul>

          <div className="mobile-nav__social">
            <a href="https://facebook.com/">
              <i className="fab fa-facebook-f" aria-hidden="true"></i>
              <span className="sr-only">Facebook</span>
            </a>
            <a href="https://twitter.com/">
              <i className="fab fa-twitter" aria-hidden="true"></i>
              <span className="sr-only">Twitter</span>
            </a>
            <a href="https://linkedin.com/" aria-hidden="true">
              <i className="fab fa-linkedin-in"></i>
              <span className="sr-only">Linkedin</span>
            </a>
            <a href="https://youtube.com/" aria-hidden="true">
              <i className="fab fa-youtube"></i>
              <span className="sr-only">Youtube</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
