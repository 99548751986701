import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Banner from '../Header/Banner'
import Preloader from '../Preloder/Preloader'
const Index = () => {

  return (
  
   
      <body className="custom-cursor">


{/* <div className="preloader">
    <div className="preloader__image" style={{backgroundImage:'url("assets/images/loader.png")'}}  ></div>
</div> */}
{/* /.preloader */}
<div className="page-wrapper">
    <Preloader/>
    <Header/>

   <Banner/>
    

    <section className="about-one about-one--home section-space">
        <div className="about-one__bg">
            <div className="about-one__bg__border"></div>{/* /.about-one__bg__border */}
            <div className="about-one__bg__inner"
                style={{backgroundImage:'url("assets/images/shapes/about-shape-1-1.png")'}}></div>
            {/* /.about-one__left__bg__inner */}
        </div>{/* /.about-one__left__bg */}
        <div className="container">
            <div className="row gutter-y-50">
                <div className="col-xl-6 wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                    <div className="about-one__left">
                        <div className="about-one__image">
                            <img src="assets/images/about/about-1-1.png" alt="about" className="about-one__image__one"/>
                            <div className="about-one__video"
                                style={{backgroundImage:'url("assets/images/about/about-1-2.png")'}}>
                                <a href="https://www.youtube.com/watch?v=h9MbznbxlLc"
                                    className="about-one__video__btn video-button video-popup">
                                    <span className="icon-play"></span>
                                    <i className="video-button__ripple"></i>
                                </a>{/* /.about-one__video__btn */}
                            </div>{/* /.about-one__video */}
                            <div className="about-one__profile volunteer-profile">
                                <div className="volunteer-profile__inner">
                                    <img src="assets/images/resources/robert-joe-kerry.png" alt="Robert Joe Kerry"
                                        className="volunteer-profile__image"/>
                                    <div className="volunteer-profile__info">
                                        <h4 className="volunteer-profile__name"><a href="volunteer-details.html">Robert
                                                Joe Kerry</a></h4>{/* /.volunteer-profile__name */}
                                        <p className="volunteer-profile__designation">Founder</p>
                                        {/* /.volunteer-profile__designation */}
                                    </div>{/* /.volunteer-profile__info */}
                                </div>{/* /.volunteer-profile__inner */}
                            </div>{/* /.about-one__profile */}
                        </div>{/* /.about-one__image */}
                    </div>{/* /.about-one__left */}
                </div>
                <div className="col-xl-6">
                    <div className="about-one__content">
                        <div className="sec-title">

                            <h6 className="sec-title__tagline @@extraClassName">ABOUT CLEAN HEART</h6>
                            {/* /.sec-title__tagline */}

                            <h3 className="sec-title__title">Helping Each Other can Make World <span
                                    className="sec-title__title__inner">Better</span></h3>{/* /.sec-title__title */}
                        </div>{/* /.sec-title */}
                        <div className="about-one__text-box wow fadeInUp" data-wow-delay="00ms"
                            data-wow-duration="1500ms">
                            <div className="about-one__text-box__image">
                                <img src="assets/images/about/about-1-3.jpg" alt="about"/>
                            </div>{/* /.about-one__text-box__image */}
                            <p className="about-one__text">We help companies develop powerful corporate social
                                responsibility, grantmaking, and employee engagement strategies.</p>
                        </div>{/* /.about-one__text-box */}
                        <div className="about-one__wrapper">
                            <div className="row gutter-y-40">
                                <div className="col-lg-4 col-sm-4 wow fadeInUp" data-wow-delay="00ms"
                                    data-wow-duration="1500ms">
                                    <div className="about-one__donate about-one__donate--one">
                                        <div className="about-one__donate__icon">
                                            <span className="icon-team"></span>
                                        </div>{/* /.about-one__donate__icon */}
                                        <h4 className="about-one__donate__title">join our team</h4>
                                        {/* /.about-one__donate__title */}
                                        <div className="about-one__donate__text">
                                            <span>6,472</span>
                                        </div>{/* /.about-one__donate__text */}
                                    </div>{/* /.about-one__donate */}
                                </div>{/* /.col-lg-4 col-sm-4 */}
                                <div className="col-lg-4 col-sm-4 wow fadeInUp" data-wow-delay="200ms"
                                    data-wow-duration="1500ms">
                                    <div className="about-one__donate about-one__donate--two">
                                        <div className="about-one__donate__icon">
                                            <span className="icon-donation"></span>
                                        </div>{/* /.about-one__donate__icon */}
                                        <h4 className="about-one__donate__title">donate now</h4>
                                        {/* /.about-one__donate__title */}
                                        <div className="about-one__donate__text">
                                            <span>$38,768</span>
                                        </div>{/* /.about-one__donate__text */}
                                    </div>{/* /.about-one__donate */}
                                </div>{/* /.col-lg-4 col-sm-4 */}
                                <div className="col-lg-4 col-sm-4 wow fadeInUp" data-wow-delay="400ms"
                                    data-wow-duration="1500ms">
                                    <div className="about-one__donate about-one__donate--three">
                                        <div className="about-one__donate__icon">
                                            <span className="icon-money"></span>
                                        </div>{/* /.about-one__donate__icon */}
                                        <h4 className="about-one__donate__title">total fund Raised</h4>
                                        {/* /.about-one__donate__title */}
                                        <div className="about-one__donate__text">
                                            <span>1,193,210</span>
                                        </div>{/* /.about-one__donate__text */}
                                    </div>{/* /.about-one__donate */}
                                </div>{/* /.col-lg-4 col-sm-4 */}
                            </div>{/* /.row */}
                        </div>{/* /.about-one__wrapper */}
                        <div className="contact-information">
                            <Link to='/aboutus' className="contact-information__btn cleenhearts-btn">
                                <div className="cleenhearts-btn__icon-box">
                                    <div className="cleenhearts-btn__icon-box__inner"><span
                                            className="icon-duble-arrow"></span></div>
                                </div>
                                <span className="cleenhearts-btn__text">more about us</span>
                            </Link>{/* /.contact-information__btn */}
                            <div className="contact-information__phone">
                                <div className="contact-information__phone__icon">
                                    <span className="icon-phone"></span>
                                </div>{/* /.contact-information__phone__icon */}
                                <div className="contact-information__phone__text">
                                    <span>call any time</span>
                                    <h5><a href="tel:"></a></h5>
                                </div>{/* /.contact-information__phone__text */}
                            </div>{/* /.contact-information__phone */}
                        </div>{/* /.contact-information */}
                    </div>{/* /.about-one__content */}
                </div>
            </div>{/* /.row */}
        </div>{/* /.container */}
        <img src="assets/images/shapes/about-shape-1-2.png" alt="cleenhearts" className="about-one__hand"/>
    </section>{/* /.about-one section-space */}

    <section className="help-donate-one section-space-top">
        <div className="help-donate-one__bg cleenhearts-jarallax" data-jarallax data-speed="0.3"
            data-imgPosition="50% -100%"
            style={{backgroundImage:'url("assets/images/backgrounds/help-donate-bg-1-1.jpg")'}}></div>
        {/* /.cta-one__bg */}
        <div className="help-donate-one__shape-one" style={{backgroundImage:'url("assets/images/shapes/shape-1.png")'}}>
        </div>{/* /.cta-one__shape-one */}
        <div className="help-donate-one__shape-two" style={{backgroundImage:'url("assets/images/shapes/shape-2.png")'}}>
        </div>{/* /.cta-one__shape-two */}
        <div className="container">
            <div className="sec-title">
                <h6 className="sec-title__tagline sec-title__tagline--center">HELP & DONATE US</h6>
                {/* /.sec-title__tagline */}
            </div>{/* /.sec-title */}
        </div>{/* /.container */}
        <div className="help-donate-one__slide">
            <span
                className="help-donate-one__text help-donate-one__text--one">medicals</span>{/* /.help-donate-one__text */}
            <img src="assets/images/resources/help-donate-1-1.png" alt="help-donate" className="help-donate-one__image"/>
            <span
                className="help-donate-one__text help-donate-one__text--two">education</span>{/* /.help-donate-one__text */}
            <img src="assets/images/resources/help-donate-1-1.png" alt="help-donate" className="help-donate-one__image"/>
            <span
                className="help-donate-one__text help-donate-one__text--one">foods</span>{/* /.help-donate-one__text */}
            <img src="assets/images/resources/help-donate-1-1.png" alt="help-donate" className="help-donate-one__image"/>
            <span
                className="help-donate-one__text help-donate-one__text--two">health</span>{/* /.help-donate-one__text */}
            <img src="assets/images/resources/help-donate-1-1.png" alt="help-donate" className="help-donate-one__image"/>
            <span
                className="help-donate-one__text help-donate-one__text--one">support</span>{/* /.help-donate-one__text */}
            <img src="assets/images/resources/help-donate-1-1.png" alt="help-donate" className="help-donate-one__image"/>
            <span
                className="help-donate-one__text help-donate-one__text--two">donation</span>{/* /.help-donate-one__text */}
            <img src="assets/images/resources/help-donate-1-1.png" alt="help-donate" className="help-donate-one__image"/>
            <span
                className="help-donate-one__text help-donate-one__text--one">medical</span>{/* /.help-donate-one__text */}
            <img src="assets/images/resources/help-donate-1-1.png" alt="help-donate" className="help-donate-one__image"/>
            <span
                className="help-donate-one__text help-donate-one__text--two">education</span>{/* /.help-donate-one__text */}
            <img src="assets/images/resources/help-donate-1-1.png" alt="help-donate" className="help-donate-one__image"/>
            <span
                className="help-donate-one__text help-donate-one__text--one">foods</span>{/* /.help-donate-one__text */}
            <img src="assets/images/resources/help-donate-1-1.png" alt="help-donate" className="help-donate-one__image"/>
            <span
                className="help-donate-one__text help-donate-one__text--two">health</span>{/* /.help-donate-one__text */}
            <img src="assets/images/resources/help-donate-1-1.png" alt="help-donate" className="help-donate-one__image"/>
            <span
                className="help-donate-one__text help-donate-one__text--one">support</span>{/* /.help-donate-one__text */}
            <img src="assets/images/resources/help-donate-1-1.png" alt="help-donate" className="help-donate-one__image"/>
            <span
                className="help-donate-one__text help-donate-one__text--two">donation</span>{/* /.help-donate-one__text */}
        </div>{/* /.help-donate-one__slide */}
    </section>{/* /.help-donate-one section-space-top */}



    <section className="donations-one donations-carousel section-space-bottom">
        <div className="container">
            <div className="donations-one__carousel cleenhearts-owl__carousel cleenhearts-owl__carousel--basic-nav owl-theme owl-carousel"
                data-owl-options='{
        "items": 3,
        "margin": 30,
        "smartSpeed": 700,
        "loop":true,
        "autoplay": 6000,
        "nav":true,
        "dots":false,
        "navText": ["<span className=\"icon-arrow-left\"></span>","<span className=\"icon-arrow-right\"></span>"],
        "responsive":{
            "0":{
                "items": 1,
                "margin": 20
            },
            "576":{
                "items": 1,
                "margin": 30
            },
            "768":{
                "items": 2,
                "margin": 30
            },
            "992":{
                "items": 2,
                "margin": 30
            },
            "1200":{
                "items": 3,
                "margin": 30
            }
        }
        }'>
                <div className="item wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="00ms">
                    <div className="donation-card @@extraClassName">
                        <div className="donation-card__bg"
                            style={{backgroundImage:'url("assets/images/backgrounds/donation-bg-1-1.png")'}}>
                        </div>{/* /.donation-card__bg */}
                        <a href="donation-details-right.html" className="donation-card__image">
                            <img src="assets/images/donations/donation-1-1.jpg"
                                alt="Give African Children's Good medical support"/>
                            <div className="donation-card__category">Medical</div>{/* /.donation-card__category */}
                        </a>{/* /.donation-card__image */}
                        <div className="donation-card__content">
                            <div className="donation-card__progress">
                                <div className="progress-box">
                                    <div className="progress-box__bar">
                                        <div className="progress-box__bar__inner count-bar--noappear"
                                            data-percent='90%'>
                                            <div className="progress-box__number count-text">90%</div>
                                        </div>
                                    </div>{/* /.progress-box__bar */}
                                </div>{/* /.progress-box */}
                                <div className="donation-card__progress__bottom">
                                    <h5 className="donation-card__progress__title">Raised $<span>80,050</span></h5>
                                    {/* /.donation-card__progress__title */}
                                    <h5 className="donation-card__progress__title">goal $<span>90,000</span></h5>
                                    {/* /.donation-card__progress__title */}
                                </div>{/* /.donation-card__progress__bottom */}
                            </div>{/* /.donation-card__progress */}
                            <h3 className="donation-card__title"><a href="donation-details-right.html">Give African
                                    Children's Good medical support</a></h3>
                            {/* /.donation-card__title */}
                            <a href="donation-details-right.html"
                                className="donation-card__btn cleenhearts-btn cleenhearts-btn--border-base">
                                <div className="cleenhearts-btn__icon-box">
                                    <div className="cleenhearts-btn__icon-box__inner"><span
                                            className="icon-duble-arrow"></span></div>
                                </div>
                                <span className="cleenhearts-btn__text">donation details</span>
                            </a>{/* /.donation-card__btn */}
                        </div>{/* /.donation-card__content */}
                    </div>{/* /.donation-card */}
                </div>{/* /.item */}
                <div className="item wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                    <div className="donation-card @@extraClassName">
                        <div className="donation-card__bg"
                            style={{backgroundImage:'url("assets/images/backgrounds/donation-bg-1-1.png")'}}>
                        </div>{/* /.donation-card__bg */}
                        <a href="donation-details-right.html" className="donation-card__image">
                            <img src="assets/images/donations/donation-1-2.jpg"
                                alt="Let’s education for children get good life"/>
                            <div className="donation-card__category">Education</div>{/* /.donation-card__category */}
                        </a>{/* /.donation-card__image */}
                        <div className="donation-card__content">
                            <div className="donation-card__progress">
                                <div className="progress-box">
                                    <div className="progress-box__bar">
                                        <div className="progress-box__bar__inner count-bar--noappear"
                                            data-percent='65%'>
                                            <div className="progress-box__number count-text">65%</div>
                                        </div>
                                    </div>{/* /.progress-box__bar */}
                                </div>{/* /.progress-box */}
                                <div className="donation-card__progress__bottom">
                                    <h5 className="donation-card__progress__title">Raised $<span>80,050</span></h5>
                                    {/* /.donation-card__progress__title */}
                                    <h5 className="donation-card__progress__title">goal $<span>90,000</span></h5>
                                    {/* /.donation-card__progress__title */}
                                </div>{/* /.donation-card__progress__bottom */}
                            </div>{/* /.donation-card__progress */}
                            <h3 className="donation-card__title"><a href="donation-details-right.html">Let’s education
                                    for children get good life</a></h3>
                            {/* /.donation-card__title */}
                            <a href="donation-details-right.html"
                                className="donation-card__btn cleenhearts-btn cleenhearts-btn--border-base">
                                <div className="cleenhearts-btn__icon-box">
                                    <div className="cleenhearts-btn__icon-box__inner"><span
                                            className="icon-duble-arrow"></span></div>
                                </div>
                                <span className="cleenhearts-btn__text">donation details</span>
                            </a>{/* /.donation-card__btn */}
                        </div>{/* /.donation-card__content */}
                    </div>{/* /.donation-card */}
                </div>{/* /.item */}
                <div className="item wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                    <div className="donation-card @@extraClassName">
                        <div className="donation-card__bg"
                            style={{backgroundImage:'url("assets/images/backgrounds/donation-bg-1-1.png")'}}>
                        </div>{/* /.donation-card__bg */}
                        <a href="donation-details-right.html" className="donation-card__image">
                            <img src="assets/images/donations/donation-1-3.jpg"
                                alt="Raise Funds For Clean & Healthy Food"/>
                            <div className="donation-card__category">Foods</div>{/* /.donation-card__category */}
                        </a>{/* /.donation-card__image */}
                        <div className="donation-card__content">
                            <div className="donation-card__progress">
                                <div className="progress-box">
                                    <div className="progress-box__bar">
                                        <div className="progress-box__bar__inner count-bar--noappear"
                                            data-percent='75%'>
                                            <div className="progress-box__number count-text">75%</div>
                                        </div>
                                    </div>{/* /.progress-box__bar */}
                                </div>{/* /.progress-box */}
                                <div className="donation-card__progress__bottom">
                                    <h5 className="donation-card__progress__title">Raised $<span>80,050</span></h5>
                                    {/* /.donation-card__progress__title */}
                                    <h5 className="donation-card__progress__title">goal $<span>90,000</span></h5>
                                    {/* /.donation-card__progress__title */}
                                </div>{/* /.donation-card__progress__bottom */}
                            </div>{/* /.donation-card__progress */}
                            <h3 className="donation-card__title"><a href="donation-details-right.html">Raise Funds For
                                    Clean & Healthy Food</a></h3>
                            {/* /.donation-card__title */}
                            <a href="donation-details-right.html"
                                className="donation-card__btn cleenhearts-btn cleenhearts-btn--border-base">
                                <div className="cleenhearts-btn__icon-box">
                                    <div className="cleenhearts-btn__icon-box__inner"><span
                                            className="icon-duble-arrow"></span></div>
                                </div>
                                <span className="cleenhearts-btn__text">donation details</span>
                            </a>{/* /.donation-card__btn */}
                        </div>{/* /.donation-card__content */}
                    </div>{/* /.donation-card */}
                </div>{/* /.item */}
                <div className="item wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="00ms">
                    <div className="donation-card @@extraClassName">
                        <div className="donation-card__bg"
                            style={{backgroundImage:'url("assets/images/backgrounds/donation-bg-1-1.png")'}}>
                        </div>{/* /.donation-card__bg */}
                        <a href="donation-details-right.html" className="donation-card__image">
                            <img src="assets/images/donations/donation-1-4.jpg"
                                alt="Quisque dictum eget accumsan dignissim. Quisque"/>
                            <div className="donation-card__category">Medical</div>{/* /.donation-card__category */}
                        </a>{/* /.donation-card__image */}
                        <div className="donation-card__content">
                            <div className="donation-card__progress">
                                <div className="progress-box">
                                    <div className="progress-box__bar">
                                        <div className="progress-box__bar__inner count-bar--noappear"
                                            data-percent='90%'>
                                            <div className="progress-box__number count-text">90%</div>
                                        </div>
                                    </div>{/* /.progress-box__bar */}
                                </div>{/* /.progress-box */}
                                <div className="donation-card__progress__bottom">
                                    <h5 className="donation-card__progress__title">Raised $<span>80,050</span></h5>
                                    {/* /.donation-card__progress__title */}
                                    <h5 className="donation-card__progress__title">goal $<span>90,000</span></h5>
                                    {/* /.donation-card__progress__title */}
                                </div>{/* /.donation-card__progress__bottom */}
                            </div>{/* /.donation-card__progress */}
                            <h3 className="donation-card__title"><a href="donation-details-right.html">Quisque dictum
                                    eget accumsan dignissim. Quisque</a></h3>
                            {/* /.donation-card__title */}
                            <a href="donation-details-right.html"
                                className="donation-card__btn cleenhearts-btn cleenhearts-btn--border-base">
                                <div className="cleenhearts-btn__icon-box">
                                    <div className="cleenhearts-btn__icon-box__inner"><span
                                            className="icon-duble-arrow"></span></div>
                                </div>
                                <span className="cleenhearts-btn__text">donation details</span>
                            </a>{/* /.donation-card__btn */}
                        </div>{/* /.donation-card__content */}
                    </div>{/* /.donation-card */}
                </div>{/* /.item */}
                <div className="item wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                    <div className="donation-card @@extraClassName">
                        <div className="donation-card__bg"
                            style={{backgroundImage:'url("assets/images/backgrounds/donation-bg-1-1.png")'}}>
                        </div>{/* /.donation-card__bg */}
                        <a href="donation-details-right.html" className="donation-card__image">
                            <img src="assets/images/donations/donation-1-5.jpg"
                                alt="Maecenas enim orci, condime ntum non risus"/>
                            <div className="donation-card__category">Education</div>{/* /.donation-card__category */}
                        </a>{/* /.donation-card__image */}
                        <div className="donation-card__content">
                            <div className="donation-card__progress">
                                <div className="progress-box">
                                    <div className="progress-box__bar">
                                        <div className="progress-box__bar__inner count-bar--noappear"
                                            data-percent='65%'>
                                            <div className="progress-box__number count-text">65%</div>
                                        </div>
                                    </div>{/* /.progress-box__bar */}
                                </div>{/* /.progress-box */}
                                <div className="donation-card__progress__bottom">
                                    <h5 className="donation-card__progress__title">Raised $<span>80,050</span></h5>
                                    {/* /.donation-card__progress__title */}
                                    <h5 className="donation-card__progress__title">goal $<span>90,000</span></h5>
                                    {/* /.donation-card__progress__title */}
                                </div>{/* /.donation-card__progress__bottom */}
                            </div>{/* /.donation-card__progress */}
                            <h3 className="donation-card__title"><a href="donation-details-right.html">Maecenas enim
                                    orci, condime ntum non risus</a></h3>
                            {/* /.donation-card__title */}
                            <a href="donation-details-right.html"
                                className="donation-card__btn cleenhearts-btn cleenhearts-btn--border-base">
                                <div className="cleenhearts-btn__icon-box">
                                    <div className="cleenhearts-btn__icon-box__inner"><span
                                            className="icon-duble-arrow"></span></div>
                                </div>
                                <span className="cleenhearts-btn__text">donation details</span>
                            </a>{/* /.donation-card__btn */}
                        </div>{/* /.donation-card__content */}
                    </div>{/* /.donation-card */}
                </div>{/* /.item */}
                <div className="item wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                    <div className="donation-card @@extraClassName">
                        <div className="donation-card__bg"
                            style={{backgroundImage:'url("assets/images/backgrounds/donation-bg-1-1.png")'}}>
                        </div>{/* /.donation-card__bg */}
                        <a href="donation-details-right.html" className="donation-card__image">
                            <img src="assets/images/donations/donation-1-6.jpg"
                                alt="Mauris maximus neque vel euismod sagittis. Vestibulum"/>
                            <div className="donation-card__category">Foods</div>{/* /.donation-card__category */}
                        </a>{/* /.donation-card__image */}
                        <div className="donation-card__content">
                            <div className="donation-card__progress">
                                <div className="progress-box">
                                    <div className="progress-box__bar">
                                        <div className="progress-box__bar__inner count-bar--noappear"
                                            data-percent='75%'>
                                            <div className="progress-box__number count-text">75%</div>
                                        </div>
                                    </div>{/* /.progress-box__bar */}
                                </div>{/* /.progress-box */}
                                <div className="donation-card__progress__bottom">
                                    <h5 className="donation-card__progress__title">Raised $<span>80,050</span></h5>
                                    {/* /.donation-card__progress__title */}
                                    <h5 className="donation-card__progress__title">goal $<span>90,000</span></h5>
                                    {/* /.donation-card__progress__title */}
                                </div>{/* /.donation-card__progress__bottom */}
                            </div>{/* /.donation-card__progress */}
                            <h3 className="donation-card__title"><a href="donation-details-right.html">Mauris maximus
                                    neque vel euismod sagittis. Vestibulum</a></h3>
                            {/* /.donation-card__title */}
                            <a href="donation-details-right.html"
                                className="donation-card__btn cleenhearts-btn cleenhearts-btn--border-base">
                                <div className="cleenhearts-btn__icon-box">
                                    <div className="cleenhearts-btn__icon-box__inner"><span
                                            className="icon-duble-arrow"></span></div>
                                </div>
                                <span className="cleenhearts-btn__text">donation details</span>
                            </a>{/* /.donation-card__btn */}
                        </div>{/* /.donation-card__content */}
                    </div>{/* /.donation-card */}
                </div>{/* /.item */}
            </div>{/* /.donations-one__carousel */}
            <div className="donations-one__row row gutter-y-30">
                <div className="col-xl-6 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="00ms">
                    <div className="donation-information">
                        <div className="donation-information__bg"
                            style={{backgroundImage:'url("assets/images/resources/donation-information-bg-1-1.jpg")'}}>
                        </div>{/* /.donation-information__bg */}
                        <div className="donation-information__content">
                            <h3 className="donation-information__title">Make the Most Out of Tax Time</h3>
                            {/* /.donation-information__title */}
                            <p className="donation-information__text">We help companies develop powerful corporate
                                social responsibility, grantmaking, and employee engagement strategies.</p>
                            {/* /.donation-information__text */}
                            <ul className="donation-information__list">
                                <li>
                                    <i className="fas fa-check"></i>
                                    <span>Donate Money</span>
                                </li>
                                <li>
                                    <i className="fas fa-check"></i>
                                    <span>handle with care</span>
                                </li>
                                <li>
                                    <i className="fas fa-check"></i>
                                    <span>Donate Money</span>
                                </li>
                            </ul>{/* /.donation-information__list */}
                            <a href="contact.html" className="cleenhearts-btn cleenhearts-btn--border">
                                <div className="cleenhearts-btn__icon-box">
                                    <div className="cleenhearts-btn__icon-box__inner"><span
                                            className="icon-duble-arrow"></span></div>
                                </div>
                                <span className="cleenhearts-btn__text">read more</span>
                            </a>
                        </div>{/* /.donation-information__content */}
                    </div>{/* /.donation-information */}
                </div>{/* /.col-xl-6 */}
                <div className="col-xl-6 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                    <div className="gift-card">
                        <div className="gift-card__bg"
                            style={{backgroundImage:'url("assets/images/resources/gift-bg-1-1.jpg")'}}></div>
                        {/* /.gift-card__bg */}
                        <div className="gift-card__content">
                            <h3 className="gift-card__title">Make a donate now</h3>{/* /.gift-card__title */}
                            <p className="gift-card__text">We help companies develop powerful corporate social
                                responsibility, grantmaking, and employee</p>{/* /.gift-card__text */}
                            <div className="gift-card__amount">
                                <form action="#" className="gift-card__form">
                                    <label for="custom_amount" className="gift-card__form__title">Custom</label>
                                    <input type="text" value="$25" name="custom-amount" id="custom_amount"
                                        className="gift-card__form__input" placeholder="Amount"/>
                                </form>{/* /.gift-card__form */}
                                <button type="button" className="gift-card__btn active">$<span
                                        className="gift-card__btn__text">25</span></button>{/* /.gift-card__btn */}
                                <button type="button" className="gift-card__btn">$<span
                                        className="gift-card__btn__text">50</span></button>{/* /.gift-card__btn */}
                                <button type="button" className="gift-card__btn">$<span
                                        className="gift-card__btn__text">99</span></button>{/* /.gift-card__btn */}
                            </div>{/* /.gift-card__amount */}
                            <a href="donate.html" className="cleenhearts-btn cleenhearts-btn--border">
                                <div className="cleenhearts-btn__icon-box">
                                    <div className="cleenhearts-btn__icon-box__inner"><span
                                            className="icon-duble-arrow"></span></div>
                                </div>
                                <span className="cleenhearts-btn__text">donate now</span>
                            </a>
                        </div>{/* /.gift-card__content */}
                    </div>{/* /.gift-card */}
                </div>{/* /.col-xl-6 */}
            </div>{/* /.row */}
        </div>{/* /.container */}
    </section>{/* /.donations-one donations-carousel section-space-bottom */}

    <section className="inspiring-one section-space"
        style={{backgroundImage:'url("assets/images/backgrounds/inspiring-bg-1-1.png")'}}>
        <div className="container">
            <div className="row gutter-y-50">
                <div className="col-xl-6">
                    <div className="sec-title">

                        <h6 className="sec-title__tagline @@extraClassName">WHO ARE WE</h6>{/* /.sec-title__tagline */}

                        <h3 className="sec-title__title">Inspiring and Helping for Better <span
                                className="sec-title__title__inner">Lifestyle</span></h3>{/* /.sec-title__title */}
                    </div>{/* /.sec-title */}
                    <p className="inspiring-one__text">Dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas
                        sit aspernaturaut odit aut fugit, sed quia consequuntur. Dicta sunt explicabo. Nemo enim
                        ipsam voluptatem quia voluptas.</p>{/* /.inspiring-one__text */}
                    <div className="inspiring-one__inner">
                        <div className="inspiring-one__inner__content wow fadeInUp" data-wow-duration="1500ms"
                            data-wow-delay="00ms">
                            <h4 className="inspiring-one__inner__title">Become A
                                Volunteer</h4>{/* /.inspiring-one__inner__title */}
                            <div className="inspiring-one__inner__icon">
                                <span className="icon-unity"></span>
                            </div>{/* /.inspiring-one__inner__icon */}
                        </div>{/* /.inspiring-one__inner__content */}
                        <div className="inspiring-one__inner__content wow fadeInUp" data-wow-duration="1500ms"
                            data-wow-delay="200ms">
                            <h4 className="inspiring-one__inner__title">medical &
                                health</h4>{/* /.inspiring-one__inner__title */}
                            <div className="inspiring-one__inner__icon">
                                <span className="icon-healthcare"></span>
                            </div>{/* /.inspiring-one__inner__icon */}
                        </div>{/* /.inspiring-one__inner__content */}
                    </div>{/* /.inspiring-one__inner */}
                </div>{/* /.col-xl-6 */}
                <div className="col-xl-6">
                    <div className="inspiring-one__image">
                        <div className="inspiring-one__image__inner inspiring-one__image__inner--one wow fadeInRight"
                            data-wow-duration="1500ms" data-wow-delay="00ms">
                            <img src="assets/images/inspiring/inspiring-1-1.jpg" alt="inspiring"/>
                        </div>{/* /.inspiring-one__image__one */}
                        <div className="inspiring-one__image__inner inspiring-one__image__inner--two wow fadeInRight"
                            data-wow-duration="1500ms" data-wow-delay="200ms">
                            <img src="assets/images/inspiring/inspiring-1-2.jpg" alt="inspiring"/>
                        </div>{/* /.inspiring-one__image__two */}
                    </div>{/* /.inspiring-one__image */}
                </div>{/* /.col-xl-6 */}
            </div>{/* /.row */}
        </div>{/* /.container */}
        <div className="inspiring-one__shapes">
            <div className="inspiring-one__shape inspiring-one__shape--one"></div>{/* /.inspiring-one__shape__one */}
            <div className="inspiring-one__shape inspiring-one__shape--two"></div>{/* /.inspiring-one__shape__one */}
        </div>{/* /.inspiring-one__shape */}
    </section>{/* /.inspiring-one-one section-space */}

    <section className="events-one section-space">
        <div className="container">
            <div className="sec-title">

                <h6 className="sec-title__tagline sec-title__tagline--center">Worldwide non profit charity</h6>
                {/* /.sec-title__tagline */}

                <h3 className="sec-title__title">See Upcoming <span className="sec-title__title__inner">Events</span></h3>
                {/* /.sec-title__title */}
            </div>{/* /.sec-title */}
            <div className="horizontal-accordion">
                <div className="events-one__card card choice">
                    <div className="card-body">
                        <div className="events-one__card__top"
                            style={{backgroundImage:'url("assets/images/events/event-bg-1-1.jpg")'}}>
                            <h4 className="events-one__card__title">Your little Help Can Heal Pains</h4>
                            <span className="events-one__card__icon icon-plus"></span>{/* /.accordion-title__icon */}
                        </div>{/* /.accordian-title */}
                        <div className="event-card-two">
                            <a href="event-details-right.html" className="event-card-two__image">
                                <img src="assets/images/events/event-2-5.jpg" alt="cleenhearts"/>
                                <div className="event-card-two__time">
                                    <span className="event-card-two__time__icon fa fa-clock"></span>Dec 13, 2024 @ 10:00
                                    am
                                </div>{/* /.event-card-four__time */}
                            </a>{/* /.event-card-four__image */}
                            <div className="event-card-two__content">
                                <h4 className="event-card-two__title"><a href="event-details-right.html">Your little
                                        Help Can Heal Pains</a></h4>{/* /.event-card-four__title */}
                                <div className="event-card-two__text">Dicta sunt explicabo. Nemo enim ipsam voluptatem
                                    quia voluptas sit aspernaturaut odit</div>{/* /.event-card-two__text */}
                                <div className="event-card-two__meta">
                                    <h5 className="event-card-two__meta__title">Vanue</h5>
                                    350 5th AveNew York, NY 10118 United States
                                </div>{/* /.event-card-four__meta */}
                            </div>{/* /.event-card-four__content */}
                        </div>{/* /.event-card-two */}
                    </div>
                </div>
                <div className="events-one__card card choice expand">
                    <div className="card-body">
                        <div className="events-one__card__top"
                            style={{backgroundImage:'url("assets/images/events/event-bg-1-2.jpg")'}}>
                            <h4 className="events-one__card__title">Let’s education for children get good</h4>
                            <span className="events-one__card__icon icon-plus"></span>{/* /.accordion-title__icon */}
                        </div>{/* /.accordian-title */}
                        <div className="event-card-two">
                            <a href="event-details-right.html" className="event-card-two__image">
                                <img src="assets/images/events/event-2-6.jpg" alt="cleenhearts"/>
                                <div className="event-card-two__time">
                                    <span className="event-card-two__time__icon fa fa-clock"></span>Dec 13, 2024 @ 10:00
                                    am
                                </div>{/* /.event-card-four__time */}
                            </a>{/* /.event-card-four__image */}
                            <div className="event-card-two__content">
                                <h4 className="event-card-two__title"><a href="event-details-right.html">Let’s education
                                        for children get good life</a></h4>{/* /.event-card-four__title */}
                                <div className="event-card-two__text">Dicta sunt explicabo. Nemo enim ipsam voluptatem
                                    quia voluptas sit aspernaturaut odit</div>{/* /.event-card-two__text */}
                                <div className="event-card-two__meta">
                                    <h5 className="event-card-two__meta__title">Vanue</h5>
                                    350 5th AveNew York, NY 10118 United States
                                </div>{/* /.event-card-four__meta */}
                            </div>{/* /.event-card-four__content */}
                        </div>{/* /.event-card-two */}
                    </div>
                </div>
                <div className="events-one__card card choice">
                    <div className="card-body">
                        <div className="events-one__card__top"
                            style={{backgroundImage:'url("assets/images/events/event-bg-1-3.jpg")'}}>
                            <h4 className="events-one__card__title">Give African Childrens Education</h4>
                            <span className="events-one__card__icon icon-plus"></span>{/* /.accordion-title__icon */}
                        </div>{/* /.accordian-title */}
                        <div className="event-card-two">
                            <a href="event-details-right.html" className="event-card-two__image">
                                <img src="assets/images/events/event-2-7.jpg" alt="cleenhearts"/>
                                <div className="event-card-two__time">
                                    <span className="event-card-two__time__icon fa fa-clock"></span>Dec 13, 2024 @ 10:00
                                    am
                                </div>{/* /.event-card-four__time */}
                            </a>{/* /.event-card-four__image */}
                            <div className="event-card-two__content">
                                <h4 className="event-card-two__title"><a href="event-details-right.html">Give African
                                        Childrens Education</a></h4>{/* /.event-card-four__title */}
                                <div className="event-card-two__text">Dicta sunt explicabo. Nemo enim ipsam voluptatem
                                    quia voluptas sit aspernaturaut odit</div>{/* /.event-card-two__text */}
                                <div className="event-card-two__meta">
                                    <h5 className="event-card-two__meta__title">Vanue</h5>
                                    350 5th AveNew York, NY 10118 United States
                                </div>{/* /.event-card-four__meta */}
                            </div>{/* /.event-card-four__content */}
                        </div>{/* /.event-card-two */}
                    </div>
                </div>
            </div>
        </div>{/* /.container */}
    </section>{/* /.events-one section-space */}

    <section className="testimonials-one section-space">
        <div className="testimonials-one__bg cleenhearts-jarallax" data-jarallax data-speed="0.3"
            data-imgPosition="50% -100%"
            style={{backgroundImage:'url("assets/images/backgrounds/testimonial-bg-1.jpg")'}}></div>
        {/* /.testimonial-one__bg */}
        <div className="container">
            <div className="testimonials-one__wrapper">
                <div className="testimonials-one__top">
                    <div className="row gutter-y-30 align-items-center">
                        <div className="col-lg-7">
                            <div className="sec-title">

                                <h6 className="sec-title__tagline @@extraClassName">TESTIMONIALS</h6>
                                {/* /.sec-title__tagline */}

                                <h3 className="sec-title__title">What They’re Talking About Clean <span
                                        className="sec-title__title__inner">Heart</span></h3>{/* /.sec-title__title */}
                            </div>{/* /.sec-title */}
                        </div>{/* /.col-lg-7 */}
                        <div className="col-lg-5 wow fadeInRight" data-wow-duration="1500ms">
                            <div className="testimonials-one__image">
                                <img src="assets/images/testimonials/testimonials-1-1.jpg" alt="testimonials"/>
                            </div>{/* /.testimonials-one__image */}
                        </div>{/* /.col-lg-5 */}
                    </div>{/* /.row gutter-y-40 */}
                </div>{/* /.testimonials-one__top */}
                <div className="testimonials-one__carousel cleenhearts-owl__carousel cleenhearts-owl__carousel--basic-nav owl-theme owl-carousel"
                    data-owl-options='{
            "items": 1,
            "margin": 10,
            "smartSpeed": 700,
            "loop":true,
            "autoplay": 6000,
            "nav":false,
            "dots":true,
            "navText": ["<span className=\"icon-arrow-left\"></span>","<span className=\"icon-arrow-right\"></span>"],
            "responsive":{
                "0":{
                    "items": 1,
                    "margin": 0
                },
                "575":{
                    "items": 1,
                    "margin": 0
                },
                "768":{
                    "items": 1,
                    "margin": 0
                },
                "992":{
                    "items": 1,
                    "margin": 0
                },
                "1200":{
                    "items": 1,
                    "margin": 0
                }
            }
            }'>
                    <div className="item wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="00ms">
                        <div className="testimonials-card">
                            <div className="testimonials-card__bg"
                                style={{backgroundImage:'url("assets/images/backgrounds/testimonial-bg-2.png")'}}>
                            </div>{/* /.testimonials-card__bg */}
                            <div className="testimonials-card__info">
                                <div className="testimonials-card__info__left">
                                    <h4 className="testimonials-card__name">Kenneth S. Fisher</h4>
                                    {/* /.testimonials-card__name */}
                                    <span className="testimonials-card__designation">marketing
                                        manager</span>{/* /.testimonials-card__designation */}
                                </div>{/* /.testimonials-card__info__left */}
                                <div className="cleenhearts-ratings testimonials-card__rattings">
                                    <span className="icon-star"></span><span className="icon-star"></span><span
                                        className="icon-star"></span><span className="icon-star"></span><span
                                        className="icon-star"></span>
                                </div>{/* /.product-ratings */}
                            </div>{/* /.testimonials-card__info */}
                            <div className="testimonials-card__content">
                                <p className="testimonials-card__text">posuere luctus orci. Donec vitae mattis quam,
                                    vitae tempor arcu. Aenean<br/> non odio porttitor, convallis erat sit amet,
                                    facilisis velit. Nulla ornare<br/> convallis malesuada. Phasellus</p>
                                {/* /.testimonials-card__text */}
                                <div className="testimonials-card__quote">
                                    <span className="icon-quote"></span>
                                </div>{/* /.testimonials-card__quote */}
                            </div>{/* /.testimonials-card__content */}
                        </div>{/* /.testimonials-card */}
                    </div>{/* /.item*/}
                    <div className="item wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="00ms">
                        <div className="testimonials-card">
                            <div className="testimonials-card__bg"
                                style={{backgroundImage:'url("assets/images/backgrounds/testimonial-bg-2.png")'}}>
                            </div>{/* /.testimonials-card__bg */}
                            <div className="testimonials-card__info">
                                <div className="testimonials-card__info__left">
                                    <h4 className="testimonials-card__name">Cedric J. Coggins</h4>
                                    {/* /.testimonials-card__name */}
                                    <span className="testimonials-card__designation">Tropical
                                        zodiac</span>{/* /.testimonials-card__designation */}
                                </div>{/* /.testimonials-card__info__left */}
                                <div className="cleenhearts-ratings testimonials-card__rattings">
                                    <span className="icon-star"></span><span className="icon-star"></span><span
                                        className="icon-star"></span><span className="icon-star"></span><span
                                        className="icon-star"></span>
                                </div>{/* /.product-ratings */}
                            </div>{/* /.testimonials-card__info */}
                            <div className="testimonials-card__content">
                                <p className="testimonials-card__text">posuere luctus orci. Donec vitae mattis quam,
                                    vitae tempor arcu. Aenean<br/> non odio porttitor, convallis erat sit amet,
                                    facilisis velit. Nulla ornare<br/> convallis malesuada. Phasellus</p>
                                {/* /.testimonials-card__text */}
                                <div className="testimonials-card__quote">
                                    <span className="icon-quote"></span>
                                </div>{/* /.testimonials-card__quote */}
                            </div>{/* /.testimonials-card__content */}
                        </div>{/* /.testimonials-card */}
                    </div>{/* /.item*/}
                    <div className="item wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="00ms">
                        <div className="testimonials-card">
                            <div className="testimonials-card__bg"
                                style={{backgroundImage:'url("assets/images/backgrounds/testimonial-bg-2.png")'}}>
                            </div>{/* /.testimonials-card__bg */}
                            <div className="testimonials-card__info">
                                <div className="testimonials-card__info__left">
                                    <h4 className="testimonials-card__name">Jhon Doe</h4>
                                    {/* /.testimonials-card__name */}
                                    <span className="testimonials-card__designation">marketing
                                        manager</span>{/* /.testimonials-card__designation */}
                                </div>{/* /.testimonials-card__info__left */}
                                <div className="cleenhearts-ratings testimonials-card__rattings">
                                    <span className="icon-star"></span><span className="icon-star"></span><span
                                        className="icon-star"></span><span className="icon-star"></span><span
                                        className="icon-star"></span>
                                </div>{/* /.product-ratings */}
                            </div>{/* /.testimonials-card__info */}
                            <div className="testimonials-card__content">
                                <p className="testimonials-card__text">posuere luctus orci. Donec vitae mattis quam,
                                    vitae tempor arcu. Aenean<br/> non odio porttitor, convallis erat sit amet,
                                    facilisis velit. Nulla ornare<br/> convallis malesuada. Phasellus</p>
                                {/* /.testimonials-card__text */}
                                <div className="testimonials-card__quote">
                                    <span className="icon-quote"></span>
                                </div>{/* /.testimonials-card__quote */}
                            </div>{/* /.testimonials-card__content */}
                        </div>{/* /.testimonials-card */}
                    </div>{/* /.item*/}
                </div>{/* /.testimonials-one__carousel */}
            </div>{/* /.testimonials-one__wrapper */}
        </div>{/* /.container */}
        <div className="testimonials-one__shape">
            <img src="assets/images/shapes/shape-1.png" alt="shape" className="testimonials-one__shape__one"/>
            <img src="assets/images/shapes/shape-2.png" alt="shape" className="testimonials-one__shape__two"/>
        </div>{/* /.testimonials-one__shape */}
    </section>{/* /.testimonials-one section-space */}

    <div className="support-one section-space">
        <div className="container">
            <div className="sec-title">

                <h6 className="sec-title__tagline sec-title__tagline--center">Worldwide non profit charity</h6>
                {/* /.sec-title__tagline */}

                <h3 className="sec-title__title">Give a Helping Hand a For Needy <span
                        className="sec-title__title__inner">People</span></h3>{/* /.sec-title__title */}
            </div>{/* /.sec-title */}
            <div className="row gutter-y-30">
                <div className="col-xl-6 col-lg-6 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="00ms">
                    <div className="support-card">
                        <a href="donate.html" className="support-card__image">
                            <img src="assets/images/support/support-1-1.jpg" alt="Foods Support"/>
                        </a>{/* /.support-card__image */}
                        <a href="donate.html" className="support-card__content">
                            <div className="support-card__content__inner">
                                <h3 className="support-card__title">Foods Support</h3>{/* /.support-card__title */}
                                <div className="support-card__collected"><span>Collected $2.5M</span></div>
                                {/* /.support-card__collected */}
                            </div>{/* /.support-card__content__inner */}
                        </a>{/* /.support-card__content */}
                    </div>{/* /.support-card */}
                </div>{/* /.col-xl-6 col-lg-6 */}
                <div className="col-xl-6 col-lg-6 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                    <div className="support-card">
                        <a href="donate.html" className="support-card__image">
                            <img src="assets/images/support/support-1-2.jpg" alt="Sports Support"/>
                        </a>{/* /.support-card__image */}
                        <a href="donate.html" className="support-card__content">
                            <div className="support-card__content__inner">
                                <h3 className="support-card__title">Sports Support</h3>{/* /.support-card__title */}
                                <div className="support-card__collected"><span>Collected $2.5M</span></div>
                                {/* /.support-card__collected */}
                            </div>{/* /.support-card__content__inner */}
                        </a>{/* /.support-card__content */}
                    </div>{/* /.support-card */}
                </div>{/* /.col-xl-6 col-lg-6 */}
                <div className="col-xl-6 col-lg-6 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="00ms">
                    <div className="support-card support-card--direction">
                        <a href="donate.html" className="support-card__content">
                            <div className="support-card__content__inner">
                                <h3 className="support-card__title">Medical Support</h3>{/* /.support-card__title */}
                                <div className="support-card__collected"><span>Collected $2.5M</span></div>
                                {/* /.support-card__collected */}
                            </div>{/* /.support-card__content__inner */}
                        </a>{/* /.support-card__content */}
                        <a href="donate.html" className="support-card__image">
                            <img src="assets/images/support/support-1-3.jpg" alt="Medical Support"/>
                        </a>{/* /.support-card__image */}
                    </div>{/* /.support-card */}
                </div>{/* /.col-xl-6 col-lg-6 */}
                <div className="col-xl-6 col-lg-6 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                    <div className="support-card support-card--direction">
                        <a href="donate.html" className="support-card__content">
                            <div className="support-card__content__inner">
                                <h3 className="support-card__title">Education Support</h3>{/* /.support-card__title */}
                                <div className="support-card__collected"><span>Collected $2.5M</span></div>
                                {/* /.support-card__collected */}
                            </div>{/* /.support-card__content__inner */}
                        </a>{/* /.support-card__content */}
                        <a href="donate.html" className="support-card__image">
                            <img src="assets/images/support/support-1-4.jpg" alt="Education Support"/>
                        </a>{/* /.support-card__image */}
                    </div>{/* /.support-card */}
                </div>{/* /.col-xl-6 col-lg-6 */}
            </div>{/* /.row */}
        </div>{/* /.container */}
        <div className="support-one__shape">
            <img src="assets/images/shapes/support-shape-hand-1-1.png" alt="shape-hand"
                className="support-one__shape__one"/>
            <img src="assets/images/shapes/support-shape-hand-1-2.png" alt="shape-hand"
                className="support-one__shape__two"/>
        </div>{/* /.support-one__shape */}
    </div>{/* /.support-one section-space */}

    <section className="help-people" style={{backgroundImage:' url("assets/images/backgrounds/help-people-bg-1.png")'}}>
        <div className="container">
            <div className="row">
                <div className="help-people__images col-xl-6">
                    <div className="help-people__images__inner">
                        <img src="assets/images/help-people/help-people-1-1.png" alt="help-people"
                            className="wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="800ms"/>
                        <img src="assets/images/help-people/help-people-1-2.png" alt="help-people"
                            className="wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="400ms"/>
                        <img src="assets/images/help-people/help-people-1-3.png" alt="help-people"
                            className="wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="00ms"/>
                    </div>{/* /.help-people__images__inner */}
                </div>{/* /.col-xl-6 */}
                <div className="help-people__content col-xl-6">
                    <div className="help-people__content__inner">
                        <div className="sec-title">

                            <h6 className="sec-title__tagline @@extraClassName">Help people now</h6>
                            {/* /.sec-title__tagline */}

                            <h3 className="sec-title__title">Charity For The People You Care <span
                                    className="sec-title__title__inner">About</span></h3>{/* /.sec-title__title */}
                        </div>{/* /.sec-title */}
                        <p className="help-people__text">Dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas
                            sit aspernaturaut odit aut fugit, sed quia consequuntur. Dicta sunt explicabo. Nemo enim
                            ipsam voluptatem quia voluptas.</p>{/* /.help-people__text */}
                        <div className="help-people__content__bottom">
                            <a href="become-a-volunteer.html" className="cleenhearts-btn">
                                <div className="cleenhearts-btn__icon-box">
                                    <div className="cleenhearts-btn__icon-box__inner"><span
                                            className="icon-duble-arrow"></span></div>
                                </div>
                                <span className="cleenhearts-btn__text">Become A Volunteer</span>
                            </a>{/* /.cleenhearts-btn */}
                            <div className="volunteer-group">
                                <a href="volunteer-details.html" className="volunteer-group__link">
                                    <img src="assets/images/volunteer/volunteer-image-1.png" alt="volunteer"
                                        className="volunteer-group__image"/>
                                </a>{/* /.volunteer-group__link */}
                                <a href="volunteer-details.html" className="volunteer-group__link">
                                    <img src="assets/images/volunteer/volunteer-image-2.png" alt="volunteer"
                                        className="volunteer-group__image"/>
                                </a>{/* /.volunteer-group__link */}
                                <a href="volunteer-details.html" className="volunteer-group__link">
                                    <img src="assets/images/volunteer/volunteer-image-3.png" alt="volunteer"
                                        className="volunteer-group__image"/>
                                </a>{/* /.volunteer-group__link */}
                            </div>{/* /.volunteer-group */}
                        </div>{/* /.help-people__content__bottom */}
                    </div>{/* /.help-people__content__inner */}
                </div>{/* /.col-xl-6 */}
            </div>{/* /.row */}
        </div>{/* /.container */}
        <div className="help-people__shape">
            <img src="assets/images/shapes/help-people-shape-1.png" alt="help-people"
                className="help-people__shape__one"/>
        </div>{/* /.help-people__shape */}
    </section>{/* /.help-people */}

    <div className="blog-one section-space">
        <div className="container">
            <div className="row">
                <div className="col-xl-6 col-lg-7 col-md-9">
                    <div className="sec-title">

                        <h6 className="sec-title__tagline @@extraClassName">Our latest news</h6>
                        {/* /.sec-title__tagline */}

                        <h3 className="sec-title__title">Latest News & Articles From Clean <span
                                className="sec-title__title__inner">Heart</span></h3>{/* /.sec-title__title */}
                    </div>{/* /.sec-title */}
                </div>{/* /.col-xl-6 col-lg-7 col-md-9 */}
            </div>{/* /.row */}
            <div className="blog-one__carousel cleenhearts-owl__carousel cleenhearts-owl__carousel--basic-nav owl-theme owl-carousel"
                data-owl-options='{
        "items": 3,
        "margin": 30,
        "smartSpeed": 700,
        "loop":true,
        "autoplay": 6000,
        "nav":false,
        "dots":true,
        "navText": ["<span className=\"icon-arrow-left\"></span>","<span className=\"icon-arrow-right\"></span>"],
        "responsive":{
            "0":{
                "items": 1,
                "margin": 20
            },
            "575":{
                "items": 1,
                "margin": 30
            },
            "768":{
                "items": 2,
                "margin": 30
            },
            "992":{
                "items": 3,
                "margin": 30
            },
            "1200":{
                "items": 3,
                "margin": 30
            }
        }
        }'>
                <div className="item">
                    <div className="blog-card wow fadeInUp" data-wow-duration='1500ms' data-wow-delay='000ms'>
                        <a href="blog-details-right.html" className="blog-card__image">
                            <img src="assets/images/blog/blog-1-1.jpg"
                                alt="Our donation is hope for poor childrens in africa"/>
                            <div className="blog-card__date"><span>03</span>
                                Sep</div>{/* /.blog-card__date */}
                        </a>{/* /.blog-card__image */}
                        <div className="blog-card__content"
                            style={{backgroundImage: 'url("assets/images/blog/blog-bg-1-1.png")'}}>
                            <h3 className="blog-card__title"><a href="blog-details-right.html">Our donation is hope for
                                    poor childrens in africa</a></h3>{/* /.blog-card__title */}
                            <a href="blog-details-right.html" className="blog-card__link">
                                <span className="blog-card__link__front"><span className="icon-duble-arrow"></span></span>
                                <span className="blog-card__link__back"><span className="icon-duble-arrow"></span>Read
                                    More</span>
                            </a>{/* /.blog-card__link */}
                        </div>{/* /.blog-card__content */}
                    </div>{/* /.blog-card */}
                </div>{/* /.item */}
                <div className="item">
                    <div className="blog-card wow fadeInUp" data-wow-duration='1500ms' data-wow-delay='200ms'>
                        <a href="blog-details-right.html" className="blog-card__image">
                            <img src="assets/images/blog/blog-1-2.jpg"
                                alt="There are many variations of Lorem Ipsum available"/>
                            <div className="blog-card__date"><span>03</span>
                                Sep</div>{/* /.blog-card__date */}
                        </a>{/* /.blog-card__image */}
                        <div className="blog-card__content"
                            style={{backgroundImage:' url("assets/images/blog/blog-bg-1-1.png")'}}>
                            <h3 className="blog-card__title"><a href="blog-details-right.html">There are many variations
                                    of Lorem Ipsum available</a></h3>{/* /.blog-card__title */}
                            <a href="blog-details-right.html" className="blog-card__link">
                                <span className="blog-card__link__front"><span className="icon-duble-arrow"></span></span>
                                <span className="blog-card__link__back"><span className="icon-duble-arrow"></span>Read
                                    More</span>
                            </a>{/* /.blog-card__link */}
                        </div>{/* /.blog-card__content */}
                    </div>{/* /.blog-card */}
                </div>{/* /.item */}
                <div className="item">
                    <div className="blog-card wow fadeInUp" data-wow-duration='1500ms' data-wow-delay='400ms'>
                        <a href="blog-details-right.html" className="blog-card__image">
                            <img src="assets/images/blog/blog-1-3.jpg"
                                alt="majority have suffered altera tion in some"/>
                            <div className="blog-card__date"><span>03</span>
                                Sep</div>{/* /.blog-card__date */}
                        </a>{/* /.blog-card__image */}
                        <div className="blog-card__content"
                            style={{backgroundImage: 'url("assets/images/blog/blog-bg-1-1.png")'}}>
                            <h3 className="blog-card__title"><a href="blog-details-right.html">majority have suffered
                                    altera tion in some</a></h3>{/* /.blog-card__title */}
                            <a href="blog-details-right.html" className="blog-card__link">
                                <span className="blog-card__link__front"><span className="icon-duble-arrow"></span></span>
                                <span className="blog-card__link__back"><span className="icon-duble-arrow"></span>Read
                                    More</span>
                            </a>{/* /.blog-card__link */}
                        </div>{/* /.blog-card__content */}
                    </div>{/* /.blog-card */}
                </div>{/* /.item */}
                <div className="item">
                    <div className="blog-card wow fadeInUp" data-wow-duration='1500ms' data-wow-delay='00ms'>
                        <a href="blog-details-right.html" className="blog-card__image">
                            <img src="assets/images/blog/blog-1-4.jpg"
                                alt="It is a long established fact that a reader"/>
                            <div className="blog-card__date"><span>03</span>
                                Sep</div>{/* /.blog-card__date */}
                        </a>{/* /.blog-card__image */}
                        <div className="blog-card__content"
                            style={{backgroundImage: 'url("assets/images/blog/blog-bg-1-1.png")'}}>
                            <h3 className="blog-card__title"><a href="blog-details-right.html">It is a long established
                                    fact that a reader</a></h3>{/* /.blog-card__title */}
                            <a href="blog-details-right.html" className="blog-card__link">
                                <span className="blog-card__link__front"><span className="icon-duble-arrow"></span></span>
                                <span className="blog-card__link__back"><span className="icon-duble-arrow"></span>Read
                                    More</span>
                            </a>{/* /.blog-card__link */}
                        </div>{/* /.blog-card__content */}
                    </div>{/* /.blog-card */}
                </div>{/* /.item */}
                <div className="item">
                    <div className="blog-card wow fadeInUp" data-wow-duration='1500ms' data-wow-delay='200ms'>
                        <a href="blog-details-right.html" className="blog-card__image">
                            <img src="assets/images/blog/blog-1-5.jpg"
                                alt="If you are going to use a of Lorem Ipsum. Class apte"/>
                            <div className="blog-card__date"><span>03</span>
                                Sep</div>{/* /.blog-card__date */}
                        </a>{/* /.blog-card__image */}
                        <div className="blog-card__content"
                            style={{backgroundImage: 'url("assets/images/blog/blog-bg-1-1.png")'}}>
                            <h3 className="blog-card__title"><a href="blog-details-right.html">If you are going to use a
                                    of Lorem Ipsum. Class apte</a></h3>{/* /.blog-card__title */}
                            <a href="blog-details-right.html" className="blog-card__link">
                                <span className="blog-card__link__front"><span className="icon-duble-arrow"></span></span>
                                <span className="blog-card__link__back"><span className="icon-duble-arrow"></span>Read
                                    More</span>
                            </a>{/* /.blog-card__link */}
                        </div>{/* /.blog-card__content */}
                    </div>{/* /.blog-card */}
                </div>{/* /.item */}
                <div className="item">
                    <div className="blog-card wow fadeInUp" data-wow-duration='1500ms' data-wow-delay='400ms'>
                        <a href="blog-details-right.html" className="blog-card__image">
                            <img src="assets/images/blog/blog-1-6.jpg"
                                alt="Nulla quam neque, inter fermen tum lacinia, inter"/>
                            <div className="blog-card__date"><span>03</span>
                                Sep</div>{/* /.blog-card__date */}
                        </a>{/* /.blog-card__image */}
                        <div className="blog-card__content"
                            style={{backgroundImage: 'url("assets/images/blog/blog-bg-1-1.png")'}}>
                            <h3 className="blog-card__title"><a href="blog-details-right.html">Nulla quam neque, inter
                                    fermen tum lacinia, inter</a></h3>{/* /.blog-card__title */}
                            <a href="blog-details-right.html" className="blog-card__link">
                                <span className="blog-card__link__front"><span className="icon-duble-arrow"></span></span>
                                <span className="blog-card__link__back"><span className="icon-duble-arrow"></span>Read
                                    More</span>
                            </a>{/* /.blog-card__link */}
                        </div>{/* /.blog-card__content */}
                    </div>{/* /.blog-card */}
                </div>{/* /.item */}
                <div className="item">
                    <div className="blog-card wow fadeInUp" data-wow-duration='1500ms' data-wow-delay='000ms'>
                        <a href="blog-details-right.html" className="blog-card__image">
                            <img src="assets/images/blog/blog-1-1.jpg"
                                alt="Our donation is hope for poor childrens in africa"/>
                            <div className="blog-card__date"><span>03</span>
                                Sep</div>{/* /.blog-card__date */}
                        </a>{/* /.blog-card__image */}
                        <div className="blog-card__content"
                            style={{backgroundImage: 'url("assets/images/blog/blog-bg-1-1.png")'}}>
                            <h3 className="blog-card__title"><a href="blog-details-right.html">Our donation is hope for
                                    poor childrens in africa</a></h3>{/* /.blog-card__title */}
                            <a href="blog-details-right.html" className="blog-card__link">
                                <span className="blog-card__link__front"><span className="icon-duble-arrow"></span></span>
                                <span className="blog-card__link__back"><span className="icon-duble-arrow"></span>Read
                                    More</span>
                            </a>{/* /.blog-card__link */}
                        </div>{/* /.blog-card__content */}
                    </div>{/* /.blog-card */}
                </div>{/* /.item */}
                <div className="item">
                    <div className="blog-card wow fadeInUp" data-wow-duration='1500ms' data-wow-delay='200ms'>
                        <a href="blog-details-right.html" className="blog-card__image">
                            <img src="assets/images/blog/blog-1-2.jpg"
                                alt="There are many variations of Lorem Ipsum available"/>
                            <div className="blog-card__date"><span>03</span>
                                Sep</div>{/* /.blog-card__date */}
                        </a>{/* /.blog-card__image */}
                        <div className="blog-card__content"
                            style={{backgroundImage:' url("assets/images/blog/blog-bg-1-1.png")'}}>
                            <h3 className="blog-card__title"><a href="blog-details-right.html">There are many variations
                                    of Lorem Ipsum available</a></h3>{/* /.blog-card__title */}
                            <a href="blog-details-right.html" className="blog-card__link">
                                <span className="blog-card__link__front"><span className="icon-duble-arrow"></span></span>
                                <span className="blog-card__link__back"><span className="icon-duble-arrow"></span>Read
                                    More</span>
                            </a>{/* /.blog-card__link */}
                        </div>{/* /.blog-card__content */}
                    </div>{/* /.blog-card */}
                </div>{/* /.item */}
                <div className="item">
                    <div className="blog-card wow fadeInUp" data-wow-duration='1500ms' data-wow-delay='400ms'>
                        <a href="blog-details-right.html" className="blog-card__image">
                            <img src="assets/images/blog/blog-1-3.jpg"
                                alt="majority have suffered altera tion in some"/>
                            <div className="blog-card__date"><span>03</span>
                                Sep</div>{/* /.blog-card__date */}
                        </a>{/* /.blog-card__image */}
                        <div className="blog-card__content"
                            style={{backgroundImage: 'url("assets/images/blog/blog-bg-1-1.png")'}}>
                            <h3 className="blog-card__title"><a href="blog-details-right.html">majority have suffered
                                    altera tion in some</a></h3>{/* /.blog-card__title */}
                            <a href="blog-details-right.html" className="blog-card__link">
                                <span className="blog-card__link__front"><span className="icon-duble-arrow"></span></span>
                                <span className="blog-card__link__back"><span className="icon-duble-arrow"></span>Read
                                    More</span>
                            </a>{/* /.blog-card__link */}
                        </div>{/* /.blog-card__content */}
                    </div>{/* /.blog-card */}
                </div>{/* /.item */}
            </div>{/* /.blog-one__carousel */}
        </div>{/* /.container */}
    </div>{/* /.blog-one section-space */}

    <section className="donars-one donars-one--home section-space"
        style={{backgroundImage:'url("assets/images/backgrounds/donors-bg.png")'}}>
        <div className="container-fluid">
            <div className="sec-title">

                <h6 className="sec-title__tagline sec-title__tagline--center">OUR DONARS</h6>
                {/* /.sec-title__tagline */}

                <h3 className="sec-title__title">Top <span className='sec-title__title__inner'>Donor</span> Countries</h3>
                {/* /.sec-title__title */}
            </div>{/* /.sec-title */}
            <div className="donars-one__row donars-one__row--top">
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-usa.png" alt="usa" className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">USA English</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-british.png" alt="british"
                        className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">british engliah</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-canada.png" alt="canada"
                        className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">canada english</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-irish.png" alt="irish" className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">irish english</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-turkish.png" alt="turkish"
                        className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">turkish</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-italian.png" alt="italian"
                        className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">italian</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-french.png" alt="french"
                        className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">french</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-japan.png" alt="japanese"
                        className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">japanese</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-chinese.png" alt="chinese"
                        className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">chinese</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-bangladesh.png" alt="bangladesh"
                        className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">bangladesh</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-malay.png" alt="malay" className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">malay</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-filipino.png" alt="filipino"
                        className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">filipino</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-portages.png" alt="portages"
                        className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">portages</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-australia.png" alt="australia"
                        className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">australia</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-usa.png" alt="usa" className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">USA English</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-british.png" alt="british"
                        className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">british engliah</span>
                </div>{ /* /.donars-one__country */}
            </div> {/* /.donars-one__row */}
            <div className="donars-one__row donars-one__row--bottom">
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-usa.png" alt="usa" className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">USA English</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-british.png" alt="british"
                        className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">british engliah</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-canada.png" alt="canada"
                        className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">canada english</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-irish.png" alt="irish" className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">irish english</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-turkish.png" alt="turkish"
                        className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">turkish</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-italian.png" alt="italian"
                        className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">italian</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-japan.png" alt="japanese"
                        className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">japanese</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-chinese.png" alt="chinese"
                        className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">chinese</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-india.png" alt="india" className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">hindi</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-bangladesh.png" alt="bangladesh"
                        className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">bangladesh</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-malay.png" alt="malay" className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">malay</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-filipino.png" alt="filipino"
                        className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">filipino</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-portages.png" alt="portages"
                        className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">portages</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-australia.png" alt="australia"
                        className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">australia</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-usa.png" alt="usa" className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">USA English</span>
                </div>{/* /.donars-one__country */}
                <div className="donars-one__country">
                    <img src="assets/images/resources/flag-british.png" alt="british"
                        className="donars-one__country__flag"/>
                    <span className="donars-one__country__text">british engliah</span>
                </div>{/* /.donars-one__country */}
            </div>{/* /.donars-one__row */}
        </div>{/* /.container-fluid */}
    </section>{/* /.donars-one section-space */}

    <div className="client-carousel @@extraClassName">
        <div className="container">
            <div className="client-carousel__one cleenhearts-owl__carousel cleenhearts-owl__carousel--basic-nav owl-theme owl-carousel"
                data-owl-options='{
        "items": 5,
        "margin": 65,
        "smartSpeed": 700,
        "loop":true,
        "autoplay": 6000,
        "nav":true,
        "dots":false,
        "navText": ["<span className=\"icon-arrow-left\"></span>","<span className=\"icon-arrow-right\"></span>"],
        "responsive":{
            "0":{
                "items": 2,
                "margin": 50
            },
            "360":{
                "items": 3,
                "margin": 40
            },
            "575":{
                "items": 4,
                "margin": 40
            },
            "768":{
                "items": 4,
                "margin": 80
            },
            "992":{
                "items": 5,
                "margin": 100
            },
            "1200":{
                "items": 6,
                "margin": 134
            }
        }
        }'>
                <div className="client-carousel__item">
                    <img src="assets/images/resources/brand-1-1.png" alt="brand" className="client-carousel__item__img"/>
                    <img src="assets/images/resources/brand-1-7.png" alt="brand"
                        className="client-carousel__item__hover-img"/>
                </div>{/* /.owl-slide-item*/}
                <div className="client-carousel__item">
                    <img src="assets/images/resources/brand-1-2.png" alt="brand" className="client-carousel__item__img"/>
                    <img src="assets/images/resources/brand-1-8.png" alt="brand"
                        className="client-carousel__item__hover-img"/>
                </div>{/* /.owl-slide-item*/}
                <div className="client-carousel__item">
                    <img src="assets/images/resources/brand-1-3.png" alt="brand" className="client-carousel__item__img"/>
                    <img src="assets/images/resources/brand-1-9.png" alt="brand"
                        className="client-carousel__item__hover-img"/>
                </div>{/* /.owl-slide-item*/}
                <div className="client-carousel__item">
                    <img src="assets/images/resources/brand-1-4.png" alt="brand" className="client-carousel__item__img"/>
                    <img src="assets/images/resources/brand-1-10.png" alt="brand"
                        className="client-carousel__item__hover-img"/>
                </div>{/* /.owl-slide-item*/}
                <div className="client-carousel__item">
                    <img src="assets/images/resources/brand-1-5.png" alt="brand" className="client-carousel__item__img"/>
                    <img src="assets/images/resources/brand-1-11.png" alt="brand"
                        className="client-carousel__item__hover-img"/>
                </div>{/* /.owl-slide-item*/}
                <div className="client-carousel__item">
                    <img src="assets/images/resources/brand-1-6.png" alt="brand" className="client-carousel__item__img"/>
                    <img src="assets/images/resources/brand-1-12.png" alt="brand"
                        className="client-carousel__item__hover-img"/>
                </div>{/* /.owl-slide-item*/}
            </div>{/* /.thm-owl__slider */}
        </div>{/* /.container */}
    </div>{/* /.client-carousel */}
</div>
<Footer/>
</body>
   
  )
}

export default Index
