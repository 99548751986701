import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Preloader from '../Preloder/Preloader'
const Contact = () => {
    return (
        <div>

            <body >
                <Preloader />
                <div className="page-wrapper">
                    <Header />
                    <section className="page-header @@extraClassName">
                        <div className="page-header__bg" style={{ backgroundImage: ' url("assets/images/backgrounds/page-header-bg-1-1.jpg")' }}></div>
                        {/* /.page-header__bg */}
                        <div className="container">
                            <h2 className="page-header__title">Contact Us</h2>
                            <ul className="cleenhearts-breadcrumb list-unstyled">
                                <li><i className="icon-home"></i> <Link to="/">Home</Link></li>
                                <li><span>Contact Us</span></li>
                            </ul>{/* /.thm-breadcrumb list-unstyled */}
                        </div>{/* /.container */}
                    </section>{/* /.page-header */}
                    <section className="contact-one section-space @@extraClassName">
                        <div className="container">
                            <div className="row gutter-y-30">
                                <div className="col-lg-6 wow fadeInUp" data-aos="fade-up-right" data-aos-duration="1000">
                                    <div className="contact-one__map">
                                        <div className="google-map contact-one__google__map">
                                            <iframe title="template google map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3948.922428766207!2d77.2475098678022!3d8.210556080052909!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b04fe8c669eff13%3A0x663b0ba37a4543ef!2sRamji%20Trust!5e0!3m2!1sen!2sin!4v1733563489053!5m2!1sen!2sin" width="600" height="450" style={{ border: '0' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="map__contact-one__google__map" ></iframe>
                                        </div>
                                        {/* /.google-map */}
                                        <div className="contact-one__info">
                                            <div className="contact-one__info__item">
                                                <div className="contact-one__info__icon">
                                                    <span className="icon-location"></span>
                                                </div>{/* /.contact-one__info__icon */}
                                                <div className="contact-one__info__content" style={{ alignItems: 'start' }} >
                                                    <h4 className="contact-one__info__title">Mailing Address</h4>
                                                    <address className="contact-one__info__text" >10/26 Koothan vilai,Palappallam Post -629159,Kanniyakumari District</address>
                                                </div>{/* /.contact-one__info__content */}
                                            </div>{/* /.contact-one__info__item */}
                                            <div className="contact-one__info__item">
                                                <div className="contact-one__info__icon">
                                                    <span className="icon-phone"></span>
                                                </div>{/* /.contact-one__info__icon */}
                                                <div className="contact-one__info__content">
                                                    <h4 className="contact-one__info__title">Quick Contact</h4>
                                                    <a href="tel:+919843976039" className="contact-one__info__text contact-one__info__text--link">Call +91 98439 76039</a>
                                                </div>{/* /.contact-one__info__content */}
                                            </div>{/* /.contact-one__info__item */}
                                            <div className="contact-one__info__item">
                                                <div className="contact-one__info__icon">
                                                    <span className="icon-envelope"></span>
                                                </div>{/* /.contact-one__info__icon */}
                                                <div className="contact-one__info__content">
                                                    <h4 className="contact-one__info__title">support email</h4>
                                                    <a href="mailto:info@ramjitrust.org" className="contact-one__info__text contact-one__info__text--link">info@ramjitrust.org</a>
                                                </div>{/* /.contact-one__info__content */}
                                            </div>{/* /.contact-one__info__item */}
                                        </div>{/* /.contact-one__info */}
                                    </div>{/* /.contact-one__map */}
                                </div>{/* /.col-lg-6 */}
                                <div className="col-lg-6 wow fadeInUp" data-aos="fade-up-left" data-aos-duration="1000">
                                    <div className="contact-one__form">
                                        <div className="contact-one__form__bg" style={{ backgroundImage: 'url("assets/images/backgrounds/contact-bg-1-1.png")' }}></div>{/* /.contact-one__form__bg */}
                                        <h2 className="contact-one__title">Leave us a Message</h2>
                                        <form className="contact-one__form__inner contact-form-validated form-one wow fadeInUp" data-wow-duration="1500ms" action="https://bracketweb.com/cleenhearts-html/inc/sendemail.php">
                                            <div className="row gutter-y-20">
                                                <div className="col-12">
                                                    <div className="form-one__control">
                                                        <input type="text" name="name" id="name" placeholder="enter your name" className="form-one__control__input" />
                                                    </div>{/* /.form-one__control */}
                                                </div>{/* /.col-12 */}
                                                <div className="col-12">
                                                    <div className="form-one__control">
                                                        <input type="text" name="email" id="email" placeholder="your email" className="form-one__control__input" />
                                                    </div>{/* /.form-one__control */}
                                                </div>{/* /.col-12 */}
                                                <div className="col-12">
                                                    <div className="form-one__control">
                                                        <input type="tel" name="phone" id="phone" placeholder="phone no" className="form-one__control__input" />
                                                    </div>{/* /.form-one__control */}
                                                </div>{/* /.col-12 */}
                                              
                                                {/* <div className="col-12">
                                        <div className="form-one__control">
                                            <select className="form-one__control__input" aria-label="Default select example">
                                                <option selected="">subject</option>
                                                <option value="1">Volunteer</option>
                                                <option value="2">Donations</option>
                                                <option value="3">Foods Support</option>
                                                <option value="4">Education Support</option>
                                                <option value="4">Medical Support</option>
                                                <option value="4">Sports Support</option>
                                            </select>
                                        </div>
                                    </div> */}
                                                <div className="col-12">
                                                    <div className="form-one__control">
                                                        <textarea name="message" id="message" cols="30" rows="10" placeholder="write message . . ." className="form-one__control__input form-one__control__message"></textarea>
                                                    </div>{/* /.form-one__control */}
                                                </div>{/* /.col-12 */}
                                                <div className="col-12">
                                                    <div className="contact-one__btn-box form-one__control">
                                                        <button type="submit" className="cleenhearts-btn @@extraClassNameBtn">
                                                            <span className="cleenhearts-btn__icon-box">
                                                                <span className="cleenhearts-btn__icon-box__inner"><span className="icon-duble-arrow"></span></span>
                                                            </span>
                                                            <span className="cleenhearts-btn__text">send message</span>
                                                        </button>
                                                    </div>{/* /.form-one__control */}
                                                </div>{/* /.col-12 */}
                                            </div>{/* /.row */}
                                        </form>{/* /.contact-one__form__inner */}
                                    </div>{/* /.contact-one__form */}
                                </div>{/* /.col-lg-6 */}
                            </div>{/* /.row */}
                        </div>{/* /.container */}
                    </section>{/* /.contact-one */}
                </div>
                <Footer />
            </body>
        </div>
    )
}

export default Contact
