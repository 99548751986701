import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Index from './component/index/Index';
import Contact from './component/contact/Contact';
import Login from './component/Login/Login';
import About from './component/About_us/About';
import Donate from './component/donate/Donate';
import Program from './component/Program/Program';
import Exam from './component/Exam/Exam';
import Certificate from './component/Certificate/Certificate';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import Signin from './component/Login/Signin';
AOS.init();
// import CustomCursor from './component/Cursor/CustomCursor'
function App() {
  return (

    <div className="App">
    
      <Router>
        <Routes>
          <Route path='/contact' element={<Contact />} />
          <Route path='/login' element={<Login />} />
          <Route path='/' element={<Index />} />
          <Route path='/aboutus' element={<About/>}/>
          <Route path='/donate' element={<Donate/>}/>   
          <Route path='/program' element={<Program/>}/>  
          <Route path='/certification' element={<Certificate/>}/>  
          <Route path='/exam' element={<Exam/>}/> 
          <Route path='/signin' element={<Signin/>}/> 
        </Routes>
      </Router>
    </div>
  );
}

export default App;




