import React from 'react';
import OwlCarousel from 'react-owl-carousel'; // Import the react-owl-carousel component
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

const Banner = () => {
  // Dynamic data for the slides
  const slides = [
    {
      bgImage: "assets/images/backgrounds/slider-1-1.jpg",
      shapeOne: "assets/images/shapes/slider-1-shape-1.png",
      shapeTwo: "assets/images/shapes/slider-1-shape-2.png",
      subtitle: "Give a helping hand for a child",
      title: ["lend a helping hand", "to who those need it"],
      volunteerImages: [
        "assets/images/volunteer/volunteer-image-1.png",
        "assets/images/volunteer/volunteer-image-2.png",
        "assets/images/volunteer/volunteer-image-3.png",
      ],
    },
    {
      bgImage: "assets/images/backgrounds/slider-1-2.jpg",
      shapeOne: "assets/images/shapes/slider-1-shape-1.png",
      shapeTwo: "assets/images/shapes/slider-1-shape-2.png",
      subtitle: "Give a helping hand for a child",
      title: ["lend a helping hand", "to who those need it"],
      volunteerImages: [
        "assets/images/volunteer/volunteer-image-1.png",
        "assets/images/volunteer/volunteer-image-2.png",
        "assets/images/volunteer/volunteer-image-3.png",
      ],
    },
    {
      bgImage: "assets/images/backgrounds/slider-1-3.jpg",
      shapeOne: "assets/images/shapes/slider-1-shape-1.png",
      shapeTwo: "assets/images/shapes/slider-1-shape-2.png",
      subtitle: "Give a helping hand for a child",
      title: ["lend a helping hand", "to who those need it"],
      volunteerImages: [
        "assets/images/volunteer/volunteer-image-1.png",
        "assets/images/volunteer/volunteer-image-2.png",
        "assets/images/volunteer/volunteer-image-3.png",
      ],
    },
  ];

  const options = {
    loop: true,
    animateOut: "fadeOut",
    animateIn: "fadeIn",
    items: 1,
    autoplay: true,
    autoplayTimeout: 7000,
    smartSpeed: 1000,
    nav: false,
    dots: true,
    margin: 0,
  };

  return (
    <div>
      <section className="main-slider-one">
        <div className="main-slider-one__wrapper">
          <OwlCarousel className="main-slider-one__carousel cleenhearts-owl__carousel owl-carousel" {...options}>
            {slides.map((slide, index) => (
              <div key={index} className="item">
                <div className="main-slider-one__item">
                  <div
                    className="main-slider-one__bg"
                    style={{ backgroundImage: `url(${slide.bgImage})` }}
                  ></div>
                  <div
                    className="main-slider-one__shape-one"
                    style={{ backgroundImage: `url(${slide.shapeOne})` }}
                  ></div>
                  <div
                    className="main-slider-one__shape-two"
                    style={{ backgroundImage: `url(${slide.shapeTwo})` }}
                  ></div>
                  <div className="main-slider-one__content">
                    <h5 className="main-slider-one__sub-title">{slide.subtitle}</h5>
                    <h2 className="main-slider-one__title">
                      <span className="main-slider-one__title__text">{slide.title[0]}</span>
                      <br />
                      <span className="main-slider-one__title__text">{slide.title[1]}</span>
                    </h2>
                    <div className="main-slider-one__btn">
                      <a href="become-a-volunteer.html" className="cleenhearts-btn">
                        <div className="cleenhearts-btn__icon-box">
                          <div className="cleenhearts-btn__icon-box__inner">
                            <span className="icon-duble-arrow"></span>
                          </div>
                        </div>
                        <span className="cleenhearts-btn__text">join with us</span>
                      </a>
                      <div className="volunteer-group">
                        {slide.volunteerImages.map((volunteerImage, idx) => (
                          <a key={idx} href="volunteer-details.html" className="volunteer-group__link">
                            <img
                              src={volunteerImage}
                              alt={`volunteer-${idx + 1}`}
                              className="volunteer-group__image"
                            />
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </section>
    </div>
  );
};

export default Banner;
