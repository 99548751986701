import React, { useState } from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { Link } from 'react-router-dom'

const Signin = () => {
    const [showPassword,setShowPassword]=useState(true)
    const HandleSelect=()=>{
        setShowPassword(!showPassword)
    }
  return (
    <div>
      <Header/>
     
    <section className="login-page section-space">
        <div className="container">
            <div className="row gutter-y-40">
                <div className="col-lg-6 wow fadeInUp" data-aos="fade-up-right"  data-aos-duration="1000">
                    <div className="login-page__image">
                        <img src="assets/images/login/login-1-1.jpg" alt="login"/>
                    </div>{/* /.login-page__image */}
                </div>
                <div className="col-lg-6 wow fadeInUp" data-aos="fade-up-left"  data-aos-duration="1000">
                    <div className="login-page__form">
                    <span className="login-page__form__top-title"><Link to="/login">log in</Link></span>
                    <span > </span>
                        <span className="login-page__form__top-title" style={{backgroundColor:'#ffd767'}}>Sign in</span>
                       
                       
                        <h2 className="login-page__form__title">welcome to our community</h2>
                        <form className="login-page__form__box form-one">
                            <div className="login-page__form__input-box form-one__control">
                                <input type="email" placeholder="Email address" className="form-one__control__input"/>
                            </div>
                            <div className="login-page__form__input-box form-one__control">
                                <input type={showPassword?'password':'text'} placeholder="Password" className="form-one__control__input"/>
                            </div>

                            <div className="login-page__form__input-box form-one__control">
                                <input type={showPassword?'password':'text'} placeholder="Confirm Password" className="form-one__control__input"/>
                            </div>
                            
                            <div className="login-page__form__input-box login-page__form__checkbox-forgot">
                                <div className="login-page__form__checkbox">
                                <input type="checkbox" id="save_login" onClick={HandleSelect}/>
                                    <label for="save_login">Show Password</label>
                                </div>
                                {/* <a href="login.html" className="login-page__form__forgot">forgot your password?</a> */}
                            </div>
                            <div className="login-page__form__input-box">
                                <button type="submit" className="cleenhearts-btn">
                                    <span className="cleenhearts-btn__icon-box">
                                        <span className="cleenhearts-btn__icon-box__inner"><span className="icon-duble-arrow"></span></span>
                                    </span>
                                    <span className="cleenhearts-btn__text">Sign in</span>
                                </button>
                            </div>
                            {/* <div className="login-page__form__or">
                                <hr className="login-page__form__or-line"/>
                                <span className="login-page__form__or-text">Or</span>
                                <hr className="login-page__form__or-line"/>
                            </div> */}
                            {/* <div className="login-page__form__signin">
                                <button type="button" className="login-page__form__signin-btn">
                                    <span>sign in with facebook</span>
                                    <img src="assets/images/resources/login-facebook.png" alt=""/>
                                </button>
                                <button type="button" className="login-page__form__signin-btn">
                                    <span>sign in with google</span>
                                    <img src="assets/images/resources/login-google.png" alt=""/>
                                </button>
                                <p className="login-page__form__register-text">don’t have an account? <a href="login.html">Register</a></p>
                            </div> */}
                        </form>
                    </div>{/* login-form */}
                </div>
            </div>
        </div>
    </section>
      <Footer/>
    </div>
  )
}

export default Signin
